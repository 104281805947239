import React,{ useState } from 'react'
import { Row, Col, Form, Typography, Table } from 'antd'
import { ModuleTopHeading } from '../../PageComponents';


const { Text } = Typography


const RentReportTable = () => {
  const [form] = Form.useForm();
  

    const columns = [
        {
            title: <Text className='text-white'>Rent Month</Text>,
            dataIndex: 'rentmonth',
        },
        {
            title: <Text className='text-white'>Rent Amount</Text>,
            dataIndex: 'rentamount',
        },
        {
            title: <Text className='text-white'>Added By</Text>,
            dataIndex: 'addedBy',
        },
        {
            title: <Text className='text-white'>Time Stamp</Text>,
            dataIndex: 'timeStamp',
            width: 170,
        },
    ];

    const data = [
        {
            key: '1',
            rentmonth: <Text className='text-white'>July</Text>,            
            rentamount: <Text className='text-white'>20,000</Text>,
            addedBy: <Text className='text-white'>Admin</Text>,
            timeStamp: <Text className='text-white'>11:02PM 9/18/16</Text>,
        },
      ];

  return (
    <div>
        <Row gutter={[24,24]}>
            <Col span={24}>
                <ModuleTopHeading 
                    name='Rent'   
                />
            </Col>
            <Col span={24}>
                <Table 
                    size='large'
                    columns={columns} 
                    dataSource={data} 
                    scroll={{x: 1000}}
                    className='table-dark pagination'
                    rowHoverable={false}
                    pagination={false}
                />
            </Col>
        </Row>
    </div>
  )
}

export {RentReportTable}