import React, { useState, useEffect } from 'react';
import { useNavigate, Route, Routes, useLocation } from 'react-router-dom';
import './index.css';
import { Layout, Menu, Image, Space, Typography } from 'antd';
import { Dashboard, Login, BeautySalon, Client, Reviews, CouponManagement, NotificationPage, PrivacyPolicyPage, TermsAndConditionPage, GenralSettingPage, ProfileSettingPage, PasswordSettingPage, ReservationPage, RoleAndPermissionPage, EmployeesPage, SupportHelpdesk } from '../pages';
import { Notifications, UserProfile } from '../components/Header';

const { Header, Sider, Content } = Layout;
const { Text } = Typography;

const RouteF = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [items, setItems] = useState([]);
  const [currentTab, setCurrentTab] = useState('1');
  const [openKeys, setOpenKeys] = useState([]);
  function getItem(label, key, icon, onClick, children, type, isActive) {
    return { key, icon, onClick, children, label, type, isActive }
  }


  useEffect(()=>{
    let tab= location?.pathname?.split("/")[1]
    tab= tab==='' ? '1':
        tab==='beautysalon' ? '2':
        tab==='client' ? '3':
        tab==='employees' ? '4':
        tab==='rolepermission' ? '5':
        tab==='reservation' ? '6':
        tab==='couponsmanagement' ? '7':
        tab==='reviews' ? '8':
        tab==='notification' ? '9':
        tab==='support' ? '10':
        tab==='termcondition' ? '11':
        tab==='privacypolcy' ? '12':
        tab==='generalsetting' ? '13':
        tab==='profilesetting' ? '14':
        tab==='passsetting' ? '15':
        '1'
    setCurrentTab(tab)
  }, [location])


  useEffect(() => {
    let itemsArr = []
      itemsArr.push(getItem('Dashboard', '1', 
      currentTab === '1'?
          <img src="/assets/icons/sidebar/dash-w.png" width={'18px'} preview={false} alt=""/>:
          <img src="/assets/icons/sidebar/dash-b.png" width={'18px'} preview={false} alt="" />, 
          () => { navigate("/", { replace: true }) },
          null,
          null,
          currentTab === '1'
      ))
      itemsArr.push(
            getItem(
              'Beauty Salons', '2',
              currentTab === '2' ? 
                <img src="/assets/icons/sidebar/bs-w.png" width={'20px'} preview={false} alt=""/> :
                <img src="/assets/icons/sidebar/bs-b.png" width={'20px'} preview={false} alt="" />,
              () => { navigate("/beautysalon", { replace: true }) },
              null,
              null,
              currentTab === '2'
            )
          );
      itemsArr.push(getItem('Clients', '3', 
      currentTab === '3'?
          <img src="/assets/icons/sidebar/clients-w.png" width={'20px'} preview={false} alt=""/>:
          <img src="/assets/icons/sidebar/clients-b.png" width={'20px'} preview={false} alt="" /> , 
          () => { navigate("/client", { replace: true }) },
          null,
          null,
          currentTab === '3'
      ))

      itemsArr.push(getItem('Employees', '4', 
          currentTab === '4'?
          <img src="/assets/icons/sidebar/emp-w.png" width={'20px'} preview={false} alt="" style={{ color: 'red' }} />:
          <img src="/assets/icons/sidebar/emp-b.png" width={'20px'} preview={false} alt="" />
           , 
          () => { navigate("/employees", { replace: true }) },
          null,
          null,
          currentTab === '4'
      ))
      

      itemsArr.push(getItem('Role & Permissions', '5', 
      currentTab === '5'?
          <img src="/assets/icons/sidebar/roleper-w.png" width={'20px'} preview={false} alt="" style={{ color: 'red' }} />:
          <img src="/assets/icons/sidebar/roleper-b.png" width={'20px'} preview={false} alt="" /> , 
          () => { navigate("/rolepermission", { replace: true }) },
          null,
          null,
          currentTab === '5'
      ))

      itemsArr.push(getItem('Reservations', '6', 
        currentTab === '6'?
          <img src="/assets/icons/sidebar/reserve-w.png" width={'20px'} preview={false} alt="" style={{ color: 'red' }} />:
          <img src="/assets/icons/sidebar/reserve-b.png" width={'20px'} preview={false} alt="" /> , 
          () => { navigate("/reservation", { replace: true }) },
          null,
          null,
          currentTab === '6'
      ))

      itemsArr.push(getItem('Coupons Management', '7', 
        currentTab === '7'?
          <img src="/assets/icons/sidebar/coupon-w.png" width={'20px'} preview={false} alt="" style={{ color: 'red' }} />:
          <img src="/assets/icons/sidebar/coupon-b.png" width={'20px'} preview={false} alt="" /> , 
          () => { navigate("/couponsmanagement", { replace: true }) },
          null,
          null,
          currentTab === '7'
      ))

      itemsArr.push(getItem('Reviews', '8', 
        currentTab === '8'?
          <img src="/assets/icons/sidebar/review-w.png" width={'20px'} preview={false} alt="" style={{ color: 'red' }} />:
          <img src="/assets/icons/sidebar/review-b.png" width={'20px'} preview={false} alt="" /> , 
          () => { navigate("/reviews", { replace: true }) },
          null,
          null,
          currentTab === '8'
      ))

      itemsArr.push(getItem('Notifications', '9', 
        currentTab === '9'?
          <img src="/assets/icons/sidebar/noti-w.png" width={'20px'} preview={false} alt="" style={{ color: 'red' }} />:
          <img src="/assets/icons/sidebar/noti-b.png" width={'20px'} preview={false} alt="" /> , 
          () => { navigate("/notification", { replace: true }) },
          null,
          null,
          currentTab === '9'
      ))


      itemsArr.push(getItem('Support & Helpdesk', '10', 
        currentTab === '10'?
          <img src="/assets/icons/sidebar/support-w.png" width={'20px'} preview={false} alt="" style={{ color: 'red' }} />:
          <img src="/assets/icons/sidebar/support-b.png" width={'20px'} preview={false} alt="" /> , 
          () => { navigate("/support", { replace: true }) },
          null,
          null,
          currentTab === '10'
      ))

      itemsArr.push(getItem('Terms & Conditions', '11', 
        currentTab === '11'?
          <img src="/assets/icons/sidebar/term-w.png" width={'20px'} preview={false} alt="" style={{ color: 'red' }} />:
          <img src="/assets/icons/sidebar/term-b.png" width={'20px'} preview={false} alt="" /> , 
          () => { navigate("/termcondition", { replace: true }) },
          null,
          null,
          currentTab === '11'
      ))


      itemsArr.push(getItem('Privacy Policy', '12', 
        currentTab === '12'?
          <img src="/assets/icons/sidebar/privacy-w.png" width={'20px'} preview={false} alt="" style={{ color: 'red' }} />:
          <img src="/assets/icons/sidebar/privacy-b.png" width={'20px'} preview={false} alt="" /> , 
          () => { navigate("/privacypolcy", { replace: true }) },
          null,
          null,
          currentTab === '12'
      ))


      itemsArr.push(getItem('General Settings', '13', 
        currentTab === '13'?
          <img src="/assets/icons/sidebar/g-set-w.png" width={'20px'} preview={false} alt="" style={{ color: 'red' }} />:
          <img src="/assets/icons/sidebar/g-set-b.png" width={'20px'} preview={false} alt="" /> , 
          () => { navigate("/generalsetting", { replace: true }) },
          null,
          null,
          currentTab === '13'
      ))

      itemsArr.push(getItem('Profile Settings', '14', 
        currentTab === '14'?
          <img src="/assets/icons/sidebar/p-set-w.png" width={'20px'} preview={false} alt="" style={{ color: 'red' }} />:
          <img src="/assets/icons/sidebar/p-set-b.png" width={'20px'} preview={false} alt="" /> , 
          () => { navigate("/profilesetting", { replace: true }) },
          null,
          null,
          currentTab === '14'
      ))

      itemsArr.push(getItem('Password Settings', '15', 
        currentTab === '15'?
          <img src="/assets/icons/sidebar/pass-w.png" width={'20px'} preview={false} alt="" style={{ color: 'red' }} />:
          <img src="/assets/icons/sidebar/pass-b.png" width={'20px'} preview={false} alt="" /> , 
          () => { navigate("/passsetting", { replace: true }) },
          null,
          null,
          currentTab === '15'
      ))



      setItems([...itemsArr])
    }, [currentTab, navigate])

 
  const onOpenChange = (keys) => {
    console.log('Dropdown Head',keys)
    setOpenKeys(keys);
  };

  return (
    <Layout style={{ height: '100vh' }}>
      <Sider breakpoint="md"
        collapsedWidth="80"
        onBreakpoint={(broken) => {
          setCollapsed(broken)
        }} trigger={null} collapsible collapsed={collapsed} className={collapsed ? 'addclass' :''} style={{ height: '100vh', overflowY: 'auto' }}>
        <div className="logo" style={{ display: 'flex', justifyContent: 'center' }}>
          <Image
            style={{ width: collapsed ? "100%" : '200px' }}
            height={'auto'}
            src="./assets/images/logo-s.png"
            alt='Salon Super Admin Panel'
            preview={false}
          />
        </div>
        <Menu
          defaultSelectedKeys={['1']}
          selectedKeys={[currentTab]}
          mode="inline"
          theme="dark"
          onOpenChange={onOpenChange}
          openKeys={openKeys}
          items={items}
          className='listitem'
        />
      </Sider>
      <Layout className="site-layout bg-light-gray">
        <Header
          className="site-layout-background header-mbl-cs shadow-c"
          style={{
            padding: 0,
            display: 'flex',
            justifyContent: 'center',
            // marginLeft:25,
            marginTop:10,
          }}
        >
          <div style={{ width: '98%', display: 'flex', justifyContent: 'space-between' }}>
            <Space align='center' size={5}>
              <div className='trigger border-0-btn' onClick={() => setCollapsed(!collapsed)}>
                <Image src='/assets/icons/star-m.png' width={'25px'} preview={false} />
              </div>
              <Text strong>Welcome To Super Admin Panel!</Text>
            </Space>
            <Space size={15}>
              <Image src='/assets/icons/language.png' width={24} preview={false} />
              <Image src='/assets/icons/question.png' width={24} preview={false} />
              <Notifications />
              <UserProfile />
            </Space>
          </div>
        </Header>
        <Content
          className="scroll-bar width-fix"
          style={{
            margin: '15px auto',
            padding: '0px 15px 25px 25px',
            minHeight: 280,
            overflowY: 'auto',
            overflowX:'hidden'
          }}
        >
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/beautysalon" element={<BeautySalon />} />
            <Route path="/client" element={<Client />} />
            <Route path='/employees' element={<EmployeesPage />} />
            <Route path='/rolepermission' element={<RoleAndPermissionPage />} />
            <Route path='/reservation' element={<ReservationPage />} />            
            <Route path="/couponsmanagement" element={<CouponManagement />} />
            <Route path="/reviews" element={<Reviews />} />
            <Route path="/notification" element={<NotificationPage />} />
            <Route path='/support' element={<SupportHelpdesk />} />
            <Route path="/termcondition" element={<TermsAndConditionPage />} />
            <Route path="/privacypolcy" element={<PrivacyPolicyPage />} />
            <Route path="/generalsetting" element={<GenralSettingPage />} />
            <Route path="/profilesetting" element={<ProfileSettingPage />} />
            <Route path='/passsetting' element={<PasswordSettingPage />} />
            <Route path="/login" element={<Login />} />
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
};

export default RouteF;
