import React, { useState } from 'react'
import { Button, Card, Col, Flex, Form, Row, Space } from 'antd'
import { GridViewClient, TableViewClient, AlertModal, ModuleTopHeading, MyDatepicker, MyInput, MyselectFix, UploadImageModal, ClientAddEditDrawer, TableViewCouponManage, GridViewCouponManage, AddEditCouponDrawer } from '../../components';


const CouponManagement = () => {
    const [ current, setCurrent ] = useState(true)
    const [form] = Form.useForm();
    const [ visible, setVisible ] = useState(false)
    const [ edititem, setEditItem ] = useState(null)
    const [ deleteitem, setDeleteItem ] = useState(false)
    const [ switchtext, setSwitchText ] = useState(false)
    const [ visibleModal, setVisibleModal ] = useState(false)
    return (
        <div>
            <Card className='shadow-c radius-12 border0 cardlg'>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <Flex justify='space-between' align='center' gap={10} wrap className='w-100'>
                            <ModuleTopHeading name='Coupons Management' onClick={()=>setVisible(true)} />
                            <Flex justify='end' className='mbl-w-100'>
                                <Button
                                    onClick={() => setCurrent(!current)}
                                    className='bg-transparent border0 p-0'
                                >
                                    {
                                        current ?
                                        <img src="/assets/icons/list-ic.png" alt="" width={60} />
                                        :
                                        <img src="/assets/icons/grid-ic.png" alt="" width={60} />
                                    }
                                </Button>
                            </Flex>
                        </Flex>                        
                    </Col>
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <Row gutter={16} align={'middle'}>
                                <Col lg={4} md={12} sm={24} xs={24}>
                                    <MyInput
                                        name='couponCode'
                                        label='Coupon Code'
                                        value={form.getFieldValue("couponCode") || ''} 
                                        className='w-100 bg-transparent'
                                    />
                                </Col>
                                <Col lg={4} md={12} sm={24} xs={24}>
                                    <MyselectFix
                                        label= 'Coupon Type'
                                        name="couponType"
                                        value={form.getFieldValue("couponType") || ''}
                                        options={[
                                            {
                                                key: 1,
                                                name: 'Fixed',
                                            },
                                            {
                                                key: 2,
                                                name: 'Percentage',
                                            }
                                        ]}
                                    />
                                </Col>
                                <Col lg={4} md={12} sm={24} xs={24}>
                                    <MyselectFix
                                        label= 'Status'
                                        name="status"
                                        value={form.getFieldValue("status") || ''}
                                        options={[
                                            {
                                                key: 1,
                                                name: 'Active',
                                            },
                                            {
                                                key: 2,
                                                name: 'Inactive',
                                            }
                                        ]}
                                    />
                                </Col>
                                <Col lg={5} md={12} sm={24} xs={24}>
                                    <MyDatepicker
                                        rangePicker
                                        label="Registration Date"
                                        name='regDate'
                                        required
                                        message="Please select registration date"
                                        value={form.getFieldValue('regDate') || ''}
                                        className='border-black'
                                    />
                                </Col>
                                <Col lg={2} md={12} sm={24} xs={24}>
                                    <Button className='btnsave brand-bg text-white border0 margin-5'>Search</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col span={24}>
                        {
                            current ?
                            <TableViewCouponManage {...{switchtext, setSwitchText, edititem , setEditItem , deleteitem, setDeleteItem, visible, setVisible, visibleModal, setVisibleModal}}/>
                            :
                            <GridViewCouponManage {...{switchtext, setSwitchText, edititem , setEditItem , deleteitem, setDeleteItem, visible, setVisible, visibleModal, setVisibleModal}} />
                        }
                    </Col>
                    
                </Row>
            </Card>
            <AddEditCouponDrawer
                visible={visible}
                edititem={edititem}
                onClose={()=> {setVisible(false);setEditItem(null)}}
            />
            <AlertModal 
                visible={deleteitem}
                deleteitem={deleteitem}
                switchtext={switchtext}
                onClose={()=>{setDeleteItem(false);setSwitchText(false)}}
            />
        </div>
    )
}

export { CouponManagement }
