import React, { useState } from 'react'
import { Button, Card, Col, Divider, Flex, Form, Image, Row, Space, Table, Typography } from 'antd'
import { ModuleTopHeading, MyInput } from '../../components';
import { useNavigate } from 'react-router-dom';
import { BookingReportTable } from '../../components/ReportComponents/BookingReportTable';
import { ExpenseReportTable } from '../../components/ReportComponents/ExpenseReportTable';
import { RentReportTable } from '../../components/ReportComponents/RentReportTable';

const { Text } = Typography

const Report = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate()
    const [ visible, setVisible ] = useState(false)
    const [ editreport, setEditReport ] = useState(null)
    const [ deleteitem, setDeleteItem ] = useState(false)
    const columns = [
        {
            title: <Text className='text-white'>Income</Text>,
            dataIndex: 'income',
            key: 'income',
        },
        {
            title: <Text className='text-white'>Rent</Text>,
            dataIndex: 'rent',
            key:'rent'
        },
        {
            title: <Text className='text-white'>Expense</Text>,
            dataIndex: 'expense',
            key:'expense'
        },
        {
            title: <Text className='text-white'>Our fees</Text>,
            dataIndex: 'fee',
            key:'fee'
        },
        {
            title: <Text className='text-white'>Bank Transfer</Text>,
            dataIndex: 'bankTransfer',
            key:'bankTransfer'
        },
        {
            title: <Text className='text-white'>Remaining</Text>,
            dataIndex: 'remaining',
            key:'remaining'
        },
    ];

    const data = [
        {
            key: '1',
            income: <Text className='text-white'>20,000</Text>,
            rent: <Text className='text-white'>10,000</Text>,
            expense: <Text className='text-white'>2000</Text>,
            fee: <Text className='text-white'>1000</Text>,
            bankTransfer: <Text className='text-white'>500</Text>,
            remaining: <Text className='text-white'>6500</Text>,
        },
        
      ];



    return (
        <div>
            <Card className='brand-bg radius-12 border0'>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <Space>
                            <Button onClick={() => navigate(-1)} className='bg-transparent border0 p-0'>
                                <Image src='/assets/icons/backarr.png' width={30} preview={false} />
                            </Button>
                            <ModuleTopHeading name='Report' />
                        </Space>                     
                    </Col>
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <Flex justify='space-between' align='center' wrap gap={10}>
                                <Space size={10} wrap>
                                    <MyInput
                                        name='name'
                                        label='Property Name'
                                        placeholder='e.g Jhon'
                                        value={form.getFieldValue("name") || ''} 
                                        className='w-100 bg-transparent'
                                        disabled
                                    />
                                    {/* <MyselectFix
                                        label= 'Year'
                                        name="year"
                                        value={form.getFieldValue("year") || ''}
                                        options={[
                                            {
                                                key: 1,
                                                name: '2024',
                                            },
                                            {
                                                key: 2,
                                                name: '2023',
                                            }
                                        ]}
                                    /> */}
                                    <MyInput
                                        name='year'
                                        label='Year'
                                        placeholder=''
                                        value={form.getFieldValue("year") || ''} 
                                        className='w-100 bg-transparent'
                                        disabled
                                    />
                                    <MyInput
                                        name='month'
                                        label='Month'
                                        placeholder=''
                                        value={form.getFieldValue("month") || ''} 
                                        className='w-100 bg-transparent'
                                        disabled
                                    />
                                    <Button className='btnsave border0'>Search</Button>
                                </Space>
                                <div>
                                    <Button className='btnsave border0' icon={<Image src='/assets/icons/print.png' preview={false} width={22} />}>Print</Button>
                                </div>
                            </Flex>
                        </Form>
                    </Col>
                    <Divider className='bg-white m-0' />
                    <Col span={24}>
                        <Space direction='vertical' size={20} className='w-100'>
                            <ModuleTopHeading name={'Overview'} />
                            <Table 
                                size='large'
                                columns={columns} 
                                dataSource={data} 
                                className='table-dark pagination'
                                rowHoverable={false}
                                pagination={false}
                            /> 
                        </Space>             
                    </Col>
                    <Col span={24}>
                        <BookingReportTable/>
                    </Col>
                    <Col span={24}>
                        <ExpenseReportTable />
                    </Col>
                    <Col span={24}>
                        <RentReportTable />
                    </Col>
                </Row>
            </Card>
        </div>
    )
}

export { Report }
