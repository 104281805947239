import React, { useState } from 'react'
import { Button, Card, Col, Flex, Form, Image, Row, Space, Table, Typography } from 'antd'
import { ActionButton, AssignTicketModal, CheckStatusModal, CommentsDrawer, ModuleTopHeading, MyDatepicker, MyInput, MySelect, MyselectFix, ViewReviewModal, ViewSupportDrawer } from '../../components';

const { Text } = Typography
const SupportHelpdesk = () => {
    const [form] = Form.useForm();
    const [ visible, setVisible ] = useState(false)
    const [ viewonly, setViewOnly ] = useState(null)
    const [ assign, setAssign ] = useState(false)
    const [ assignid, setAssignId ] = useState(null)
    const [ commentdrawer, setCommentDrawer] = useState(false)
    const [ commentstate, setCommentState ] = useState(null)
    const [ checkstatus, setCheckStatus ] = useState(false)
    const [ changeinput, setChangeInput ] = useState('Ticket Number')

    const handleChangeFilter = (e) => {
        setChangeInput(e)
    }

    const columns = [
        {
            title:
                <Flex align='center' gap={4}>
                    <Text>
                        #
                    </Text>
                    <img src="/assets/icons/arrow-down.png" width={16} alt="" />
                </Flex>,
            dataIndex: 'index',
            width:80
        },
        {
            title: 
                <Flex align='center' gap={4}>
                    <Text>
                        Ticket Number
                    </Text>
                    <img src="/assets/icons/arrow-down.png" width={16} alt="" />
                </Flex>,
            dataIndex: 'ticketNumber',
            width: 200,
        },
        {
            title: 
                <Flex align='center' gap={4}>
                    <Text>
                        Salon Name
                    </Text>
                    <img src="/assets/icons/arrow-down.png" width={16} alt="" />
                </Flex>,
            dataIndex: 'salonName',
            width: 200,
        },
        {
            title: 
                <Flex align='center' gap={4}>
                    <Text>
                        Contact Number
                    </Text>
                    <img src="/assets/icons/arrow-down.png" width={16} alt="" />
                </Flex>,
            dataIndex: 'contactNumber',
            width: 200,
        },
        {
            title: 
                <Flex align='center' gap={4}>
                    <Text>
                        Category
                    </Text>
                    <img src="/assets/icons/arrow-down.png" width={16} alt="" />
                </Flex>,
            dataIndex: 'category',
            width:120,
        },
        {
            title: 
                <Flex align='center' gap={4}>
                    <Text>
                        Subject
                    </Text>
                    <img src="/assets/icons/arrow-down.png" width={16} alt="" />
                </Flex>,
            dataIndex: 'subject',
            width:130,
        },
        {
            title:
                <Flex align='center' gap={4}>
                    <Text>
                        Status
                    </Text>
                    <img src="/assets/icons/arrow-down.png" width={16} alt="" />
                </Flex>,
            dataIndex: 'status',
            render: (status) => {
                return (
                    status !== 1 ? (
                        <Space align='center' className='cursor' onClick={()=> setCheckStatus(true)}>
                            <img src="/assets/icons/online.png" width={'8px'} alt="" />
                            <Text className='text-active' style={{transform:"translateY(-2px)",display:'block'}}>Resolved</Text>
                        </Space>
                    ) : status === 2 ? (
                        <Space align='center' className='cursor' onClick={()=> setCheckStatus(true)}>
                            <img src="/assets/icons/inprogress.png" width={'8px'} alt="" />
                            <Text className='text-progress' style={{transform:"translateY(-2px)",display:'block'}}>In Progress</Text>
                        </Space>
                    ) :  status === 3 ?(
                        <Space align='center' className='cursor' onClick={()=> setCheckStatus(true)}>
                            <img src="/assets/icons/offline.png" width={'8px'} alt="" />
                            <Text className='text-inactive' style={{transform:"translateY(-2px)",display:'block'}}>Pending</Text>
                        </Space>
                    ) : (
                        <Space align='center' className='cursor' onClick={()=> setCheckStatus(true)}>
                            <img src="/assets/icons/offline.png" width={'8px'} alt="" />
                            <Text className='text-inactive' style={{transform:"translateY(-2px)",display:'block'}}>Closed</Text>
                        </Space>
                    )
                );
            },
            width: 170,
        },
        {
            title:
                <Flex align='center' gap={4}>
                    <Text>
                        Assigned To
                    </Text>
                    <img src="/assets/icons/arrow-down.png" width={16} alt="" />
                </Flex>,
            dataIndex: 'assignedTo',
            width: 170,
        },
        {
            title:
                <Flex align='center' gap={4}>
                    <Text>
                        Priority
                    </Text>
                    <img src="/assets/icons/arrow-down.png" width={16} alt="" />
                </Flex>,
            dataIndex: 'priority',
            render: (priority) => {
                return (
                    priority !== 1 ? (
                        <Text className='text-inactive' style={{transform:"translateY(-2px)",display:'block'}}>High</Text>
                    ) : priority === 2 ? (
                        <Text className='pink-text' style={{transform:"translateY(-2px)",display:'block'}}>Medium</Text>
                    ) : priority === 3 ? (
                        <Text className='text-brand' style={{transform:"translateY(-2px)",display:'block'}}>Low</Text>
                    ) : (
                        <Text style={{transform:"translateY(-2px)",display:'block'}}>Urgent</Text>
                    )
                );
            },
            width: 170,
        },
        {
            title:
                <Flex align='center' gap={4}>
                    <Text>
                        Time Stamp
                    </Text>
                    <img src="/assets/icons/arrow-down.png" width={16} alt="" />
                </Flex>,
            dataIndex: 'timeStamp',
            width: 170,
        },
        {
            title: <Text>Action</Text>,
            key: 'action',
            fixed: 'right',
            width: 130,
            align:'center',
            render: (_, row) => (
                <Space>
                    <ActionButton
                        title='View'
                        shape="circle"
                        icon={<Image src='/assets/icons/lighteye.png' width={18} preview={false} />}
                        onClick={() => { setVisible(true); setViewOnly({ticketnumber: row?.ticketNumber})}}
                        className='border0 bg-transparent'
                    />
                    <ActionButton
                        title='Comment'
                        shape="circle"
                        icon={<Image src='/assets/icons/comment.png' width={18} preview={false} />}
                        onClick={() => { setCommentDrawer(true); setCommentState({ticketnumber: row?.ticketNumber,name: row?.salonName})}}
                        className='border0 bg-transparent'
                    />
                    <ActionButton
                        title='Assign ticket'
                        shape="circle"
                        icon={<Image src='/assets/icons/assign-ticket.png' width={16} preview={false} />}
                        onClick={() => { setAssign(true); setAssignId({ticketnumber: row?.ticketNumber})}}
                        className='border0 bg-transparent'
                    />
                </Space>
            ),
        },
    ];

    const data = [
        {
            key: '1',
            index:'#11',
            ticketNumber: <Text>487441</Text>,
            salonName: <Text>Classique Curls</Text>,
            contactNumber: <Text>+966 110 113 087</Text>,
            category: <Text>Technical</Text>,
            subject: <Text>Order_017926</Text>,
            assignedTo: <Text>Not Assigned</Text>,
            timeStamp: <Text>11:02PM 9/18/16</Text>,
        },
        {
            key: '2',
            index:'#12',
            ticketNumber: <Text>547441</Text>,
            salonName: <Text>HH Salon</Text>,
            contactNumber: <Text>+966 110 113 087</Text>,
            category: <Text>Technical</Text>,
            subject: <Text>Order_017926</Text>,
            assignedTo: <Text>Not Assigned</Text>,
            timeStamp: <Text>11:02PM 9/18/16</Text>,
        },
        {
            key: '3',
            index:'#13',
            ticketNumber: <Text>109441</Text>,
            salonName: <Text>Classique Curls</Text>,
            contactNumber: <Text>+966 110 113 087</Text>,
            category: <Text>Technical</Text>,
            subject: <Text>Order_017926</Text>,
            assignedTo: <Text>Not Assigned</Text>,
            timeStamp: <Text>11:02PM 9/18/16</Text>,
        },
        {
            key: '4',
            index:'#14',
            ticketNumber: <Text>187917</Text>,
            salonName: <Text>Classique Curls</Text>,
            contactNumber: <Text>+966 110 113 087</Text>,
            category: <Text>Technical</Text>,
            subject: <Text>Order_017926</Text>,
            assignedTo: <Text>Not Assigned</Text>,
            timeStamp: <Text>11:02PM 9/18/16</Text>,
        },
      ];

    return (
        <div>
            <Card className='shadow-c radius-12 border0 cardlg'>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <ModuleTopHeading name='Support & Helpdesk' />                      
                    </Col>
                    <Col span={24}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <Row gutter={16} align={'middle'}>
                                <Col lg={5} md={12} sm={24} xs={24}>
                                    <MySelect
                                        label= 'Filter'
                                        name="filter"
                                        value={form.getFieldValue("filter") || ''}
                                        onChange={handleChangeFilter}
                                        options={[
                                            {
                                                key: 1,
                                                name: 'Ticket Number',
                                            },
                                            {
                                                key: 2,
                                                name: 'Salon Name',
                                            },
                                            {
                                                key: 3,
                                                name: 'Assignee Name',
                                            }
                                        ]}
                                    />
                                </Col>
                                <Col lg={5} md={12} sm={24} xs={24}>
                                    {
                                        changeinput === 'Ticket Number' ? (
                                            <MyInput
                                                type='number'
                                                name='ticketName'
                                                label='Ticket Number'
                                                value={form.getFieldValue("ticketName") || ''}
                                                className='w-100 bg-transparent'
                                            />
                                        ) : changeinput === 'Salon Name' ? (
                                            <MyInput
                                                name='salonName'
                                                label='Salon Name'
                                                value={form.getFieldValue("salonName") || ''}
                                                className='w-100 bg-transparent'
                                            />
                                        ) : (
                                            <MyInput
                                                name='assigneeName'
                                                label='Assignee Name'
                                                value={form.getFieldValue("assigneeName") || ''}
                                                className='w-100 bg-transparent'
                                            />
                                        )
                                    }
                                </Col>
                                <Col lg={5} md={12} sm={24} xs={24}>
                                    <MyDatepicker
                                        rangePicker
                                        label="Registration Date"
                                        name='regDate'
                                        required
                                        message="Please select registration date"
                                        value={form.getFieldValue('regDate') || ''}
                                        className='border-black'
                                    />
                                </Col>
                                <Col lg={3} md={12} sm={24} xs={24}>
                                    <Button className='btnsave brand-bg text-white border0 margin-5'>Search</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col span={24}>
                        <Table 
                            size='large'
                            columns={columns} 
                            dataSource={data} 
                            scroll={{x: 1500}}
                            className='table-dark pagination'
                            rowHoverable={false}
                            pagination={{
                                hideOnSinglePage: true,
                                total: 12,
                                // pageSize: pagination?.pageSize,
                                // defaultPageSize: pagination?.pageSize,
                                // current: pagination?.pageNo,
                                // size: "default",
                                // pageSizeOptions: ['10', '20', '50', '100'],
                                // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                                showTotal: (total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>,
                            }}
                        />
                    </Col>
                </Row>
            </Card>
            <ViewSupportDrawer 
                visible={visible}
                viewonly={viewonly}
                onClose={()=> {setVisible(false);setViewOnly(null)}}
            />
            <AssignTicketModal 
                visible={assign}
                assignid={assignid}
                onClose={()=> { setAssign(false); setAssignId(null) }}
            />
            <CommentsDrawer 
                visible={commentdrawer}
                commentstate={commentstate}
                onClose={()=>{ setCommentDrawer(false);setCommentState(null) }}
            />
            <CheckStatusModal 
                visible={checkstatus}
                onClose={()=>setCheckStatus(false)}
            />
        </div>
    )
}

export { SupportHelpdesk }
