import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col ,Button, Space, message, Typography, Upload, Image} from "antd"
import { MyInput, MySelect, NolabelInputSm } from "../../Forms"
import { ServicesReservationTable } from "../ServicesReservationTable";

const { Title } = Typography;
const { Dragger } = Upload;

const ViewReservationDrawer = ({visible, onClose, viewonly}) => {
  
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)  
  
    useEffect(() => {
        if (visible && viewonly) {
            
        } else {
            form.resetFields();
        }
    }, [visible, viewonly])

    const closeDrawer= ()=>{
     
        form.resetFields()
        onClose()
    }

  return (
    <Drawer
        title={viewonly?.name}
        onClose={closeDrawer}
        className="drawer"
        open={visible}
        width={1200}
        footer={false}
    >
        <Form
            form={form}
            layout="vertical"
            initialValues={true}
            // onFinish={onFinish}
            requiredMark={false}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Title level={5} className="mb-3 mt-0 fw-500">RESERVATION DETAILS</Title>
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Client'
                        name='client'
                        placeholder='e.g Kidzo Doe'
                        size={'large'}
                        value={form.getFieldValue("client") || ''}
                        disabled
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        name="contactNo"
                        label="Contact Number"
                        type='number'
                        size={'large'}
                        value={form.getFieldValue("contactNo") || ''}
                        disabled
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Payment Method'
                        name='paymentMethod'
                        placeholder='e.g Cash'
                        size={'large'}
                        value={form.getFieldValue("paymentMethod") || ''}
                        disabled
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MySelect
                        label= 'Status'
                        name="status" 
                        size={'large'}
                        value={form.getFieldValue("status") || ''}
                        options={[
                            {
                                key: 1,
                                name: 'Completed'
                            },
                            {
                                key: 2,
                                name: 'Cancelled'
                            },
                            {
                                key: 3,
                                name: 'In Progress'
                            },
                        ]}
                        disabled
                    />
                </Col>
                <Col span={24}>
                    <Title level={5} className="my-3 fw-500">CALCULATIONS</Title>
                </Col>
                <Col lg={{span: 8}} md={{span: 12}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Tax'
                        name='tax'
                        placeholder='e.g SAR 20'
                        size={'large'}
                        value={form.getFieldValue("tax") || ''}
                        disabled
                    />
                </Col>
                <Col lg={{span: 8}} md={{span: 12}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Commission'
                        name='commission'
                        placeholder='e.g SAR 20'
                        size={'large'}
                        value={form.getFieldValue("commission") || ''}
                        disabled
                    />
                </Col>
                <Col lg={{span: 8}} md={{span: 12}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Wallet Amount'
                        name='walletAmount'
                        placeholder='e.g SAR 20'
                        size={'large'}
                        value={form.getFieldValue("walletAmount") || ''}
                        disabled
                    />
                </Col>
                <Col lg={{span: 8}} md={{span: 12}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Other Amount'
                        name='otherAmount'
                        placeholder='e.g SAR 20'
                        size={'large'}
                        value={form.getFieldValue("otherAmount") || ''}
                        disabled
                    />
                </Col>
                <Col lg={{span: 8}} md={{span: 12}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Sub Total'
                        name='subtotal'
                        placeholder='e.g SAR 20'
                        size={'large'}
                        value={form.getFieldValue("otherAmount") || ''}
                        disabled
                    />
                </Col>
                <Col lg={{span: 8}} md={{span: 12}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Coupon Discount'
                        name='couponDiscount'
                        placeholder='e.g SAR 20'
                        size={'large'}
                        value={form.getFieldValue("couponDiscount") || ''}
                        disabled
                    />
                </Col>
                <Col lg={{span: 8}} md={{span: 12}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Total'
                        name='total'
                        placeholder='e.g SAR 20'
                        size={'large'}
                        value={form.getFieldValue("total") || ''}
                        disabled
                    />
                </Col>
                <Col span={24}>
                    <Title level={5} className="my-3 fw-500">SERVICES</Title>
                </Col>
                <Col span={24}>
                    <ServicesReservationTable />
                </Col>
            </Row>
        </Form>
    </Drawer>
  )
}

export {ViewReservationDrawer}