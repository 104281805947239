import React, { useState } from 'react'
import { Card, Col, Row, Typography,Space, Image, theme, Flex, Switch } from 'antd'
import { ActionButton } from '../../PageComponents';

const { useToken } = theme;
const { Text } = Typography
const GridViewReservation = ({ setDeleteItem, setViewDrawer, setViewOnly}) => {
    const { token } = useToken();


    

    const data = [
        {
            title: 'Reservation # 234',
            name: 'Jihad Bakir',
            salonname: 'Beauty DD Salon',
            contacts:[
                {
                    icons:'currency.png',
                    title:'SAR 25'
                },
                {
                    icons:'payment.png',
                    title:'Cash'
                },
                {
                    icons:'status-show.png',
                    title:'Completed',
                    key:'complete',
                },
            ]
        },
        {
            title: 'Reservation # 23',
            name: 'Sajid Hussain',
            salonname: 'Classique Curls',
            contacts:[
                {
                    icons:'currency.png',
                    title:'SAR 25'
                },
                {
                    icons:'payment.png',
                    title:'Cash'
                },
                {
                    icons:'status-show.png',
                    title:'Cancelled',
                    key:'cancel',
                },
            ]
        },
        {
            title: 'Reservation # 222',
            name: 'Zuhair Hafeez',
            salonname: 'Hair Parlour',
            contacts:[
                {
                    icons:'currency.png',
                    title:'SAR 25'
                },
                {
                    icons:'payment.png',
                    title:'Cash'
                },
                {
                    icons:'status-show.png',
                    title:'In Progress',
                    key:'inprogress',
                },
            ]
        },
        {
            title: 'Reservation # 245',
            name: 'Fayez Ali',
            salonname: 'HIVE HAIR SALON',
            contacts:[
                {
                    icons:'currency.png',
                    title:'SAR 25'
                },
                {
                    icons:'payment.png',
                    title:'Cash'
                },
                {
                    icons:'status-show.png',
                    title:'Completed',
                    key:'complete',
                },
            ]
        },
        {
            title: 'Reservation # 24',
            name: 'Nadeem Abbas',
            salonname: 'Braids & Layers',
            contacts:[
                {
                    icons:'currency.png',
                    title:'SAR 25'
                },
                {
                    icons:'payment.png',
                    title:'Cash'
                },
                {
                    icons:'status-show.png',
                    title:'Completed',
                    key:'complete',
                },
            ]
        },
        {
            title: 'Reservation # 34',
            name: 'Fahd Bakir',
            salonname: 'Haute Hair',
            contacts:[
                {
                    icons:'currency.png',
                    title:'SAR 25'
                },
                {
                    icons:'payment.png',
                    title:'Cash'
                },
                {
                    icons:'status-show.png',
                    title:'Completed',
                    key:'complete',
                },
            ]
        },
        {
            title: 'Reservation # 234',
            name: 'Mohammed Darwish',
            salonname: 'Hair Artistry',
            contacts:[
                {
                    icons:'currency.png',
                    title:'SAR 25'
                },
                {
                    icons:'payment.png',
                    title:'Cash'
                },
                {
                    icons:'status-show.png',
                    title:'Cancelled',
                    key:'cancel',
                },
            ]
        },
        {
            title: 'Reservation # 432',
            name: 'Salim Al Tajir',
            salonname: 'Curls & More',
            contacts:[
                {
                    icons:'currency.png',
                    title:'SAR 25'
                },
                {
                    icons:'payment.png',
                    title:'Cash'
                },
                {
                    icons:'status-show.png',
                    title:'Completed',
                    key:'complete',
                },
            ]
        },
    ]
  return (
    <div>
        <Row gutter={[16,16]}>
            {
                data?.map((cdata,c)=>
                    <Col xs={24} sm={24} md={12} lg={8} xl={6} key={c}>
                        <Card style={{
                            borderRadius: token.borderCardrad,
                            width:"100%"}}
                            className=''
                            actions={[
                                <Space gap={10}>
                                    <ActionButton
                                        title='View'
                                        shape="circle"
                                        icon={<Image src='/assets/icons/eye.png' width={20} preview={false} />}
                                        onClick={() => { setViewDrawer(true);setViewOnly({name: cdata?.title})}}
                                        className='border0 bg-transparent'
                                    />
                                    <ActionButton
                                        title='Delete item'
                                        icon={<Image src='/assets/icons/delete.png' width={22} preview={false} />}
                                        onClick={() => { setDeleteItem({name: cdata?.title}) }}
                                        className='border0 bg-transparent'
                                    />
                                </Space>
                            ]}
                        >
                        <Flex vertical gap={5} align='center'>
                            <Flex gap={0} vertical align='center'>
                                <Text strong>{cdata?.title}</Text>
                                <Text className='text-gray fs-12'>{cdata?.name}</Text>
                                <Text className='text-brand fw-500 fs-13'>{cdata?.salonname}</Text>
                            </Flex>
                            <Flex vertical align='start' gap={10}  className='my-3 w-100'>
                                {
                                    cdata?.contacts?.map((contact,index)=>
                                        <Flex gap={8} align='center' key={index}>
                                            <div className='icon bg-secondary-color'>
                                                <img src={"/assets/icons/"+contact?.icons} width={20} alt="" />
                                            </div>
                                            <Text 
                                                className={
                                                    contact?.key === 'complete' ? 'text-active' :
                                                    contact?.key === 'inprogress' ? 'text-progress' :
                                                    contact?.key === 'cancel' ? 'text-inactive' :
                                                    ''
                                                }
                                            >
                                                {contact?.title}
                                            </Text>
                                        </Flex>
                                    )
                                }
                            </Flex>
                        </Flex>
                    </Card>  
                </Col>
                )
            }
        </Row>
    </div>
  )
}

export {GridViewReservation}