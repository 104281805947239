import React, { useState } from 'react'
import { Card, Col, Row, Typography,Space, Image, theme, Flex, Switch } from 'antd'
import { ActionButton } from '../../PageComponents';

const { useToken } = theme;
const { Title, Text } = Typography
const GridViewBeauty = ({setEditItem, setDeleteItem, setVisible, setSwitchText}) => {
    const { token } = useToken();

    const onChange = (checked) => {
        console.log(`switch to ${checked}`);
        setDeleteItem(true)
        setSwitchText(true)
    };
    

    const data = [
        {
            banner:'bannerimg-1.png',
            title:'HIVE HAIR SALON',
            desc:'Where fashion meets happiness',
            location:'Labkhah, Saudi Arabia',
            contacts:[
                {
                    icons:'phone.png',
                    title:'+12 345 6789 0'
                },
                {
                    icons:'email.png',
                    title:'angelamoss@mail.com'
                },
            ]
        },
        {
            banner:'bannerimg-2.png',
            title:'Classique Curls',
            desc:'Where fashion meets happiness',
            location:'Labkhah, Saudi Arabia',
            contacts:[
                {
                    icons:'phone.png',
                    title:'+12 345 6789 0'
                },
                {
                    icons:'email.png',
                    title:'angelamoss@mail.com'
                },
            ]
        },
        {
            banner:'bannerimg-3.png',
            title:'Braids & Layers',
            desc:'Where fashion meets happiness',
            location:'Labkhah, Saudi Arabia',
            contacts:[
                {
                    icons:'phone.png',
                    title:'+12 345 6789 0'
                },
                {
                    icons:'email.png',
                    title:'angelamoss@mail.com'
                },
            ]
        },
        {
            banner:'bannerimg-4.png',
            title:'The Cleanup',
            desc:'Where fashion meets happiness',
            location:'Labkhah, Saudi Arabia',
            contacts:[
                {
                    icons:'phone.png',
                    title:'+12 345 6789 0'
                },
                {
                    icons:'email.png',
                    title:'angelamoss@mail.com'
                },
            ]
        },
        {
            banner:'bannerimg-1.png',
            title:'HIVE HAIR SALON',
            desc:'Where fashion meets happiness',
            location:'Labkhah, Saudi Arabia',
            contacts:[
                {
                    icons:'phone.png',
                    title:'+12 345 6789 0'
                },
                {
                    icons:'email.png',
                    title:'angelamoss@mail.com'
                },
            ]
        },
        {
            banner:'bannerimg-2.png',
            title:'Classique Curls',
            desc:'Where fashion meets happiness',
            location:'Labkhah, Saudi Arabia',
            contacts:[
                {
                    icons:'phone.png',
                    title:'+12 345 6789 0'
                },
                {
                    icons:'email.png',
                    title:'angelamoss@mail.com'
                },
            ]
        },
        {
            banner:'bannerimg-3.png',
            title:'Braids & Layers',
            desc:'Where fashion meets happiness',
            location:'Labkhah, Saudi Arabia',
            contacts:[
                {
                    icons:'phone.png',
                    title:'+12 345 6789 0'
                },
                {
                    icons:'email.png',
                    title:'angelamoss@mail.com'
                },
            ]
        },
        {
            banner:'bannerimg-4.png',
            title:'The Cleanup',
            desc:'Where fashion meets happiness',
            location:'Labkhah, Saudi Arabia',
            contacts:[
                {
                    icons:'phone.png',
                    title:'+12 345 6789 0'
                },
                {
                    icons:'email.png',
                    title:'angelamoss@mail.com'
                },
            ]
        },
    ]
  return (
    <div>
        <Row gutter={[16,16]}>
            {
                data?.map((cdata,c)=>
                    <Col xs={24} sm={24} md={12} lg={8} xl={6} key={c}>
                        <Card style={{
                            borderRadius: token.borderCardrad,
                            width:"100%"}}
                            actions={[
                                <Space gap={10}>
                                    <Switch size='small' defaultChecked onChange={onChange} />
                                    <ActionButton
                                        title='Edit item'
                                        shape="circle"
                                        icon={<Image src='/assets/icons/edit.png' width={20} preview={false} />}
                                        onClick={() => { setVisible(true)}}
                                        className='border0 bg-transparent'
                                    />
                                    <ActionButton
                                        title='Delete item'
                                        icon={<Image src='/assets/icons/delete.png' width={22} preview={false} />}
                                        onClick={() => { setDeleteItem({name: cdata?.title}) }}
                                        className='border0 bg-transparent'
                                    />
                                </Space>
                            ]}
                        >
                        <Flex vertical gap={3} align='center'>
                            <Image src={'/assets/images/'+cdata?.banner} preview={false} className='radius-12' />
                            <Flex align='center' gap={5} className='pt-3'>
                                <img src="/assets/icons/online.png" width={10} alt="" />
                                <Text strong>{cdata?.title}</Text>
                            </Flex>
                            <Text className='text-gray'>{cdata?.desc}</Text>
                            <Text className='text-brand fw-500' >{cdata?.location}</Text>
                            <Flex vertical align='start' gap={10}  className='my-3 w-100'>
                                {
                                    cdata?.contacts?.map((contact,index)=>
                                        <Flex gap={5} align='center'>
                                            <div className='icon bg-secondary-color'>
                                                <img src={"/assets/icons/"+contact?.icons} width={20} alt="" />
                                            </div>
                                            <Text strong>{contact?.title}</Text>
                                        </Flex>
                                    )
                                }
                            </Flex>
                        </Flex>
                    </Card>  
                </Col>
                )
            }
        </Row>
    </div>
  )
}

export {GridViewBeauty}