import React, { useState } from 'react'
import { Card, Col, Row, Typography,Space, Image, theme, Skeleton, Tooltip, Flex } from 'antd'

const { useToken } = theme;
const { Title, Text } = Typography
const CardComponent = () => {
    const { token } = useToken();
    const [ skeletonactive, setSkeletonActive] = useState(false)

    // setTimeout(() => {
    //     setSkeletonActive(false)
    // }, 2000);

    const data = [
        {
            title:'4250',
            subtitle:'Total Salons',
            img:'totalsalon.png',
        },
        {
            title:'2435',
            subtitle:'Total Clients',
            img:'totalclient.png',
        },
        {
            title:'250',
            subtitle:'Total Reservations',
            img:'total-res.png',
            info:'Total monthly fees from all apartments',
            key:'info'
        },
        {
            title:'425K',
            subtitle:'Total Revenue',
            img:'total-rev.png',
            info:'Monthly general income after AirBnB and Tax fee',
            key:'info'
        },
    ]
  return (
    <div>
        <Row gutter={[16,16]}>
            {
                data?.map((cdata,c)=>
                    <Col xs={24} sm={24} md={12} lg={8} xl={6} key={c}>
                        {
                            skeletonactive?
                                <Skeleton.Button
                                    active
                                    size='large' 
                                    shape='square'
                                    block
                                    style={{width:'100%', height:'114px',borderRadius:20}}
                                />
                                :
                                <Card style={{
                                    borderColor: token.borderCardcolor,
                                    borderRadius: token.borderCardrad,
                                    width:"100%"}}
                                    className='card-cs border0 shadow-c height-100'
                                >
                                    <Row justify='space-between' align='middle'>
                                        <Col lg={18} md={16} xs={18} sm={18}>
                                            <div className='my-2'>
                                                <Space size={3} align='center'>
                                                    <Title level={3} className='my-0'>
                                                        {cdata?.title}
                                                    </Title>  
                                                    {
                                                        cdata.key==='info' ?
                                                        <Tooltip title={cdata?.info}>
                                                            <img src="/assets/icons/info.png" width={22} alt="" />    
                                                        </Tooltip>
                                                        :
                                                        ''
                                                    }    
                                                </Space>                                          
                                                <Title className='my-0 fs-14 fw-500' level={5}>{cdata?.subtitle}</Title>
                                            </div>
                                        </Col>
                                        <Col lg={6} md={8} xs={6} sm={6}>
                                            <Flex justify='end'>
                                                <Image src={'/assets/icons/'+cdata?.img} width={'45px'} /> 
                                            </Flex>  
                                        </Col>
                                    </Row>
                                </Card> 
                                }  
                    </Col>
                )
            }
        </Row>
    </div>
  )
}

export {CardComponent}