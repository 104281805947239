import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col ,Button, Typography, Flex, Table} from "antd"

const { Title, Text } = Typography;

const ActivityLogEmployee = ({visible, onClose, activityId}) => {
  
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)  
  
    useEffect(() => {
        if (visible && activityId) {
            
        } else {
            form.resetFields();
        }
    }, [visible, activityId])

    const closeDrawer= ()=>{
     
        form.resetFields()
        onClose()
    }

    const columns = [
        {
            title:
                <Flex align='center' gap={4}>
                    <Text>
                        Time Stamp
                    </Text>
                    <img src="/assets/icons/arrow-down.png" width={16} alt="" />
                </Flex>,
            dataIndex: 'timeStamp',
        },
        {
            title: 
                <Flex align='center' gap={4}>
                    <Text>
                        Activity
                    </Text>
                    <img src="/assets/icons/arrow-down.png" width={16} alt="" />
                </Flex>,
            dataIndex: 'activity',
        },
    ];

    const data = [
        {
            key: '1',
            timeStamp: <Text>11:03 11/02/2024</Text>,
            activity: <Text>Resolve ticket #124567</Text>,
        },
        {
            key: '1',
            timeStamp: <Text>12:03 11/02/2024</Text>,
            activity: <Text>Client “Sameh Amin” added successfully</Text>,
        },
        {
            key: '1',
            timeStamp: <Text>11:03 11/02/2024</Text>,
            activity: <Text>Client “Amin” deleted successfully</Text>,
        },
        {
            key: '1',
            timeStamp: <Text>11:03 11/02/2024</Text>,
            activity: <Text>Salon “HH Salon” added successfully</Text>,
        },
      ];

  return (
    <Drawer
        title='Activity log'
        onClose={closeDrawer}
        className="drawer"
        open={visible}
        width={600}
        footer={
            <Flex justify="center" className="w-100" >
                <Button 
                    onClick={closeDrawer}
                    className="border0 text-brand fw-500"
                >
                    Clear Log
                </Button>
            </Flex>
        }
    >
        <Row gutter={16}>
            <Col span={24}>
                <Title level={5} className="mt-0 mb-3 fw-500">ACTIVITY DETAILS</Title>
            </Col>
            <Col span={24}>
                <Table 
                    size='large'
                    columns={columns} 
                    dataSource={data} 
                    className='table-dark pagination'
                    rowHoverable={false}
                    pagination={false}
                />
            </Col>
        </Row>
    </Drawer>
  )
}

export {ActivityLogEmployee}