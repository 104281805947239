import React, { useEffect, useState } from "react"
import { Form, Row, Col, Modal, Flex, Image} from "antd"


const ViewImageModal = ({visible, onClose}) => {
  
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)  
  
    useEffect(() => {
        if (visible) {
            
        } else {
            form.resetFields();
        }
    }, [visible])

    const data = [
        {
            img:'av-1.png'
        },
        {
            img:'av-1.png'
        },
    ]

  return (
    <Modal
        title={'Beauty Salon Gallery'}
        open={visible}
        width={800}
        footer={false}
        centered 
        onOk={onClose} 
        onCancel={onClose}
    >
        <Row gutter={[16,16]}>
            <Col span={24}>
                <Flex gap={10} wrap className="py-4">
                    {
                        data?.map((data,index)=>
                            <Image src={"/assets/images/"+data?.img} width={100} className="radius-12" key={index} />
                        )
                    }
                </Flex>
            </Col>
        </Row>
    </Modal>
  )
}

export {ViewImageModal}