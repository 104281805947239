import React,{ useState } from 'react'
import { Button, Row, Col, Form, Typography, Table, Image, Space, Avatar, Switch, Flex } from 'antd'
import { ActionButton } from '../../PageComponents';


const { Text } = Typography


const TableViewReviews = ({ setDeleteItem, setVisible }) => {
    const [form] = Form.useForm();

    const columns = [
        {
            title:
                <Flex align='center' gap={4}>
                    <Text>
                        #
                    </Text>
                    <img src="/assets/icons/arrow-down.png" width={16} alt="" />
                </Flex>,
            dataIndex: 'index',
            width:80
        },
        {
            title:
                <Flex align='center' gap={4}>
                    <Text>
                        Reservation No
                    </Text>
                    <img src="/assets/icons/arrow-down.png" width={16} alt="" />
                </Flex>,
            dataIndex: 'regNo',
        },
        {
            title:
                <Flex align='center' gap={4}>
                    <Text>
                        Salon Name
                    </Text>
                    <img src="/assets/icons/arrow-down.png" width={16} alt="" />
                </Flex>,
            dataIndex: 'salonName',
        },
        {
            title: 
                <Flex align='center' gap={4}>
                    <Text>
                        Client Name
                    </Text>
                    <img src="/assets/icons/arrow-down.png" width={16} alt="" />
                </Flex>,
            dataIndex: 'clientName',
        },
        {
            title: 
                <Flex align='center' gap={4}>
                    <Text>
                        Contact Number
                    </Text>
                    <img src="/assets/icons/arrow-down.png" width={16} alt="" />
                </Flex>,
            dataIndex: 'contactNumber',
            width:170,
        },
        {
            title: 
                <Flex align='center' gap={4}>
                    <Text>
                        Salon Rate
                    </Text>
                    <img src="/assets/icons/arrow-down.png" width={16} alt="" />
                </Flex>,
            dataIndex: 'salonRate',
        },
        {
            title: 
                <Flex align='center' gap={4}>
                    <Text>
                        Emp. Rate
                    </Text>
                    <img src="/assets/icons/arrow-down.png" width={16} alt="" />
                </Flex>,
            dataIndex: 'empRate',
        },
        {
            title: 
                <Flex align='center' gap={4}>
                    <Text>
                        Time Stamp
                    </Text>
                    <img src="/assets/icons/arrow-down.png" width={16} alt="" />
                </Flex>,
            dataIndex: 'timeStamp',
        },
        {
            title: <Text>Action</Text>,
            key: 'action',
            fixed: 'right',
            width: 100,
            render: (_, row) => (
                <Space>
                    <ActionButton
                        title='Review'
                        shape="circle"
                        icon={<Image src='/assets/icons/review.png' width={16} preview={false} />}
                        onClick={() => { setVisible({name: row?.clientName});}}
                        className='border0 bg-transparent'
                    />
                    <ActionButton
                        title='Delete item'
                        icon={<Image src='/assets/icons/delete.png' width={20} preview={false} />}
                        onClick={() => { setDeleteItem({name:row?.clientName}) }}
                        className='border0 bg-transparent'
                    />
                </Space>
            ),
        },
    ];

    const data = [
        {
            key: '1',
            index:'#11',
            regNo: <Text>478</Text>,
            salonName: <Text>Hair Flow</Text>,
            clientName: <Text>Sajid Hussain</Text>,
            contactNumber: <Text>+966 110 113 087</Text>,
            salonRate: <Space size={0}>
                <Image src='/assets/icons/star.png' width={20} preview={false} />
                <Image src='/assets/icons/star.png' width={20} preview={false} />
                <Image src='/assets/icons/star.png' width={20} preview={false} />
            </Space>,
            empRate: <Space size={0}>
                <Image src='/assets/icons/star.png' width={20} preview={false} />
                <Image src='/assets/icons/star.png' width={20} preview={false} />
                <Image src='/assets/icons/star.png' width={20} preview={false} />
            </Space>,
            timeStamp: <Text>11:02PM 9/18/16</Text>,
        },
        {
            key: '2',
            index:'#12',
            regNo: <Text>508</Text>,
            salonName: <Text>Hair Artistry</Text>,
            clientName: <Text>Sameh Amin</Text>,
            contactNumber: <Text>+966 798 328 129</Text>,
            salonRate: <Space size={0}>
                <Image src='/assets/icons/star.png' width={20} preview={false} />
                <Image src='/assets/icons/star.png' width={20} preview={false} />
                <Image src='/assets/icons/star.png' width={20} preview={false} />
                <Image src='/assets/icons/star.png' width={20} preview={false} />
                <Image src='/assets/icons/star.png' width={20} preview={false} />
            </Space>,
            empRate: <Space size={0}>
                <Image src='/assets/icons/star.png' width={20} preview={false} />
                <Image src='/assets/icons/star.png' width={20} preview={false} />
                <Image src='/assets/icons/star.png' width={20} preview={false} />
                <Image src='/assets/icons/star.png' width={20} preview={false} />
                <Image src='/assets/icons/star.png' width={20} preview={false} />
            </Space>,
            timeStamp: <Text>11:02PM 9/18/16</Text>,
        },
        {
            key: '3',
            index:'#13',
            regNo: <Text>600</Text>,
            salonName: <Text>Braids & Layers</Text>,
            clientName: <Text>Fayez Ali</Text>,
            contactNumber: <Text>+966 798 328 129</Text>,
            salonRate: <Space size={0}>
                <Image src='/assets/icons/star.png' width={20} preview={false} />
                <Image src='/assets/icons/star.png' width={20} preview={false} />
                <Image src='/assets/icons/star.png' width={20} preview={false} />
                <Image src='/assets/icons/star.png' width={20} preview={false} />
                <Image src='/assets/icons/star.png' width={20} preview={false} />
            </Space>,
            empRate: <Space size={0}>
                <Image src='/assets/icons/star.png' width={20} preview={false} />
                <Image src='/assets/icons/star.png' width={20} preview={false} />
                <Image src='/assets/icons/star.png' width={20} preview={false} />
                <Image src='/assets/icons/star.png' width={20} preview={false} />
                <Image src='/assets/icons/star.png' width={20} preview={false} />
            </Space>,
            timeStamp: <Text>11:02PM 9/18/16</Text>,
        },
        {
            key: '4',
            index:'#14',
            regNo: <Text>620</Text>,
            salonName: <Text>Haute Hair</Text>,
            clientName: <Text>Sidra Amin</Text>,
            contactNumber: <Text>+966 798 328 129</Text>,
            salonRate: <Space size={0}>
                <Image src='/assets/icons/star.png' width={20} preview={false} />
                <Image src='/assets/icons/star.png' width={20} preview={false} />
                <Image src='/assets/icons/star.png' width={20} preview={false} />
                <Image src='/assets/icons/star.png' width={20} preview={false} />
            </Space>,
            empRate: <Space size={0}>
                <Image src='/assets/icons/star.png' width={20} preview={false} />
                <Image src='/assets/icons/star.png' width={20} preview={false} />
                <Image src='/assets/icons/star.png' width={20} preview={false} />
                <Image src='/assets/icons/star.png' width={20} preview={false} />
            </Space>,
            timeStamp: <Text>11:02PM 9/18/16</Text>,
        },
      ];

  return (
    <div>
            <Row gutter={[24,24]}>
                <Col span={24}>
                    <Table 
                        size='large'
                        columns={columns} 
                        dataSource={data} 
                        scroll={{x: 1300}}
                        className='table-dark pagination'
                        rowHoverable={false}
                        pagination={{
                            hideOnSinglePage: true,
                            total: 12,
                            // pageSize: pagination?.pageSize,
                            // defaultPageSize: pagination?.pageSize,
                            // current: pagination?.pageNo,
                            // size: "default",
                            // pageSizeOptions: ['10', '20', '50', '100'],
                            // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                            showTotal: (total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>,
                        }}
                    />
                </Col>
            </Row>
    </div>
  )
}

export {TableViewReviews}