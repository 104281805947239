import React,{ useState } from 'react'
import { Button, Row, Col, Form, Typography, Table, Image, Space, Avatar, Switch, Flex } from 'antd'
import { ActionButton } from '../../PageComponents';


const { Text } = Typography


const TableViewEmployees = ({ setDeleteItem, setEditItem, setVisible, setSwitchText, setActivityLog, setActivityId }) => {
    const [form] = Form.useForm();

    const onChange = (checked) => {
        console.log(`switch to ${checked}`);
        setDeleteItem(true)
        setSwitchText(true)
    };

    const columns = [
        {
            title:
                <Flex align='center' gap={4}>
                    <Text>
                        #
                    </Text>
                    <img src="/assets/icons/arrow-down.png" width={16} alt="" />
                </Flex>,
            dataIndex: 'index',
            width:80
        },
        {
            title: 
                <Flex align='center' gap={4}>
                    <Text>
                        Employee Name
                    </Text>
                    <img src="/assets/icons/arrow-down.png" width={16} alt="" />
                </Flex>,
            dataIndex: 'employeeName',
            width: 200,
        },
        {
            title: 
                <Flex align='center' gap={4}>
                    <Text>
                        Role
                    </Text>
                    <img src="/assets/icons/arrow-down.png" width={16} alt="" />
                </Flex>,
            dataIndex: 'role',
        },
        {
            title: 
                <Flex align='center' gap={4}>
                    <Text>
                        Contact Number
                    </Text>
                    <img src="/assets/icons/arrow-down.png" width={16} alt="" />
                </Flex>,
            dataIndex: 'contactNumber',
            width: 200,
        },
        {
            title: 
                <Flex align='center' gap={4}>
                    <Text>
                        Gender
                    </Text>
                    <img src="/assets/icons/arrow-down.png" width={16} alt="" />
                </Flex>,
            dataIndex: 'gender',
            width:120,
        },
        {
            title: 
                <Flex align='center' gap={4}>
                    <Text>
                        Added By
                    </Text>
                    <img src="/assets/icons/arrow-down.png" width={16} alt="" />
                </Flex>,
            dataIndex: 'addedBy',
            width:130,
        },
        {
            title:
                <Flex align='center' gap={4}>
                    <Text>
                        Status
                    </Text>
                    <img src="/assets/icons/arrow-down.png" width={16} alt="" />
                </Flex>,
            dataIndex: 'status',
            render: (status) => {
                return (
                    status !== 1 ? (
                        <Space align='center'>
                            <img src="/assets/icons/online.png" width={'8px'} alt="" />
                            <Text className='text-active' style={{transform:"translateY(-2px)",display:'block'}}>Active</Text>
                        </Space>
                    ) : (
                        <Space>
                            <img src="/assets/icons/offline.png" width={'8px'} alt="" />
                            <Text className='text-inactive' style={{transform:"translateY(-2px)",display:'block'}}>Inactive</Text>
                        </Space>
                    )
                );
            },
        },
        {
            title:
                <Flex align='center' gap={4}>
                    <Text>
                        Time Stamp
                    </Text>
                    <img src="/assets/icons/arrow-down.png" width={16} alt="" />
                </Flex>,
            dataIndex: 'timeStamp',
            width: 170,
        },
        {
            title: <Text>Action</Text>,
            key: 'action',
            fixed: 'right',
            width: 170,
            render: (_, row) => (
                <Space>
                    <Switch size='small' defaultChecked onChange={onChange} />
                    <ActionButton
                        title='Edit employee'
                        shape="circle"
                        icon={<Image src='/assets/icons/edit.png' width={18} preview={false} />}
                        onClick={() => { setVisible(true); setEditItem({name: row?.employeeName})}}
                        className='border0 bg-transparent'
                    />
                    <ActionButton
                        title='Activity log'
                        shape="circle"
                        icon={<Image src='/assets/icons/activitylog.png' width={18} preview={false} />}
                        onClick={() => { setActivityLog(true); setActivityId(row?.id)}}
                        className='border0 bg-transparent'
                    />
                    <ActionButton
                        title='Delete employee'
                        icon={<Image src='/assets/icons/delete.png' width={20} preview={false} />}
                        onClick={() => { setDeleteItem({name:row?.employeeName}) }}
                        className='border0 bg-transparent'
                    />
                </Space>
            ),
        },
    ];

    const data = [
        {
            key: '1',
            index:'#11',
            employeeName: <Text>Sajid Hussain</Text>,
            role: <Text>Admin</Text>,
            contactNumber: <Text>+966 110 113 087</Text>,
            gender: <Text>Male</Text>,
            addedBy: <Text>Super Admin</Text>,
            timeStamp: <Text>11:02PM 9/18/16</Text>,
        },
        {
            key: '2',
            index:'#12',
            employeeName: <Text>Zuhair Hafeez</Text>,
            role: <Text>Data Entry</Text>,
            contactNumber: <Text>+966 110 113 087</Text>,
            gender: <Text>Male</Text>,
            addedBy: <Text>Super Admin</Text>,
            timeStamp: <Text>11:02PM 9/18/16</Text>,
        },
        {
            key: '3',
            index:'#13',
            employeeName: <Text>Sidra Amin</Text>,
            role: <Text>Admin</Text>,
            contactNumber: <Text>+966 110 113 087</Text>,
            gender: <Text>Female</Text>,
            addedBy: <Text>Client</Text>,
            timeStamp: <Text>11:02PM 9/18/16</Text>,
        },
        {
            key: '4',
            index:'#14',
            employeeName: <Text>Fayez Ali</Text>,
            role: <Text>Data Entry</Text>,
            contactNumber: <Text>+966 110 113 087</Text>,
            gender: <Text>Male</Text>,
            addedBy: <Text>Super Admin</Text>,
            timeStamp: <Text>11:02PM 9/18/16</Text>,
        },
      ];

  return (
    <div>
        <Row gutter={[24,24]}>
            <Col span={24}>
                <Table 
                    size='large'
                    columns={columns} 
                    dataSource={data} 
                    scroll={{x: 1300}}
                    className='table-dark pagination'
                    rowHoverable={false}
                    pagination={{
                        hideOnSinglePage: true,
                        total: 12,
                        // pageSize: pagination?.pageSize,
                        // defaultPageSize: pagination?.pageSize,
                        // current: pagination?.pageNo,
                        // size: "default",
                        // pageSizeOptions: ['10', '20', '50', '100'],
                        // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                        showTotal: (total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>,
                    }}
                />
            </Col>
        </Row>
    </div>
  )
}

export {TableViewEmployees}