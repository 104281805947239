import React from 'react'
import './index.css'
import { SearchInput } from '../../Forms'
import { Form, Image } from 'antd'

const suffix = (
    <Image src='/assets/icons/search.png' width={'18px'} preview={false} />
  )

const LocationMap = () => {

    const [form] = Form.useForm();

  return (
    <div className='iframediv mb-3'>
        <Form
            form={form}
            layout="vertical"
            className='searchbar'
        >
            <SearchInput
                name='name'
                label='Search'
                prefix={suffix}
                placeholder='Search here ...'
                value={form.getFieldValue("name") || ''} 
                className='w-100 bg-full-white radius-12'
                onChange={(e)=>e.target.value}
                style={{height:40}}
            />
        </Form>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d387191.0360977185!2d-74.30933341658171!3d40.69753995848721!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2s!4v1725858098987!5m2!1sen!2s" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
  )
}

export {LocationMap}