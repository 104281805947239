import React,{ useState } from 'react'
import { Button, Row, Col, Form, Typography, Table, Image, Space, Avatar, Switch, Flex } from 'antd'
import { ActionButton } from '../../PageComponents';


const { Text } = Typography


const TableViewCouponManage = ({ setEditItem, setDeleteItem, setVisible, setSwitchText }) => {
    const [form] = Form.useForm();
    
  
    const onChange = (checked) => {
        console.log(`switch to ${checked}`);
        setDeleteItem(true)
        setSwitchText(true)
    };

    const columns = [
        {
            title:
                <Flex align='center' gap={4}>
                    <Text>
                        #
                    </Text>
                    <img src="/assets/icons/arrow-down.png" width={16} alt="" />
                </Flex>,
            dataIndex: 'index',
            width:80
        },
        {
            title:
                <Flex align='center' gap={4}>
                    <Text>
                        Coupon Code
                    </Text>
                    <img src="/assets/icons/arrow-down.png" width={16} alt="" />
                </Flex>,
            dataIndex: 'couponCode',
            width:150,
        },
        {
            title:
                <Flex align='center' gap={4}>
                    <Text>
                        Number of Use
                    </Text>
                    <img src="/assets/icons/arrow-down.png" width={16} alt="" />
                </Flex>,
            dataIndex: 'numberUse',
            width:150,
        },
        {
            title:
                <Flex align='center' gap={4}>
                    <Text>
                        Coupon Type
                    </Text>
                    <img src="/assets/icons/arrow-down.png" width={16} alt="" />
                </Flex>,
            dataIndex: 'couponType',
            width: 150
        },
        {
            title:
                <Flex align='center' gap={4}>
                    <Text>
                        Amount
                    </Text>
                    <img src="/assets/icons/arrow-down.png" width={16} alt="" />
                </Flex>,
            dataIndex: 'amount',
        },
        {
            title:
                <Flex align='center' gap={4}>
                    <Text>
                        Added By
                    </Text>
                    <img src="/assets/icons/arrow-down.png" width={16} alt="" />
                </Flex>,
            dataIndex: 'addedBy',
        },
        {
            title:
                <Flex align='center' gap={4}>
                    <Text>
                        Role
                    </Text>
                    <img src="/assets/icons/arrow-down.png" width={16} alt="" />
                </Flex>,
            dataIndex: 'role',
        },
        {
            title:
                <Flex align='center' gap={4}>
                    <Text>
                        Status
                    </Text>
                    <img src="/assets/icons/arrow-down.png" width={16} alt="" />
                </Flex>,
            dataIndex: 'status',
            render: (status) => {
                return (
                    status !== 1 ? (
                        <Space align='center'>
                            <img src="/assets/icons/online.png" width={'8px'} alt="" />
                            <Text className='text-active' style={{transform:"translateY(-2px)",display:'block'}}>Active</Text>
                        </Space>
                    ) : (
                        <Space>
                            <img src="/assets/icons/offline.png" width={'8px'} alt="" />
                            <Text className='text-inactive' style={{transform:"translateY(-2px)",display:'block'}}>Inactive</Text>
                        </Space>
                    )
                );
            }
        },
        {
            title: 
                <Flex align='center' gap={4}>
                    <Text>
                        Time Stamp
                    </Text>
                    <img src="/assets/icons/arrow-down.png" width={16} alt="" />
                </Flex>,
            dataIndex: 'timeStamp',
            width: 160,
        },
        {
            title: <Text>Action</Text>,
            key: 'action',
            fixed: 'right',
            width: 150,
            render: (_, row) => (
                <Space>
                    <Switch size='small' defaultChecked onChange={onChange} />
                    <ActionButton
                        title='Edit item'
                        shape="circle"
                        icon={<Image src='/assets/icons/edit.png' width={20} preview={false} />}
                        onClick={() => { setVisible(true); setEditItem(row?.couponCode)}}
                        className='border0 bg-transparent'
                    />
                    <ActionButton
                        title='Delete item'
                        icon={<Image src='/assets/icons/delete.png' width={22} preview={false} />}
                        onClick={() => { setDeleteItem({name:row?.couponCode}) }}
                        className='border0 bg-transparent'
                    />
                </Space>
            ),
        },
    ];

    const data = [
        {
            key: '1',
            index:'#11',
            couponCode: <Text>FREESHIP75</Text>,
            numberUse: <Text>67</Text>,
            couponType: <Text>Percentage</Text>,
            amount: <Text>37</Text>,
            addedBy: <Text>Super Admin</Text>,
            role: <Text>Data Entry</Text>,
            timeStamp: <Text>11:02PM 9/18/16</Text>,
        },
        {
            key: '2',
            index:'#12',
            couponCode: <Text>BOGO50</Text>,
            numberUse: <Text>87</Text>,
            couponType: <Text>Percentage</Text>,
            amount: <Text>57</Text>,
            addedBy: <Text>Super Admin</Text>,
            role: <Text>Data Entry</Text>,
            timeStamp: <Text>11:02PM 9/18/16</Text>,
        },
        {
            key: '3',
            index:'#13',
            couponCode: <Text>HURRY</Text>,
            numberUse: <Text>65</Text>,
            couponType: <Text>Fixed</Text>,
            amount: <Text>44</Text>,
            addedBy: <Text>Super Admin</Text>,
            role: <Text>Data Entry</Text>,
            timeStamp: <Text>11:02PM 9/18/16</Text>,
        },
        {
            key: '4',
            index:'#14',
            couponCode: <Text>SALE25NOW</Text>,
            numberUse: <Text>67</Text>,
            couponType: <Text>Percentage</Text>,
            amount: <Text>37</Text>,
            addedBy: <Text>Super Admin</Text>,
            role: <Text>Data Entry</Text>,
            timeStamp: <Text>11:02PM 9/18/16</Text>,
        },
      ];

  return (
    <div>
            <Row gutter={[24,24]}>
                <Col span={24}>
                    <Table 
                        size='large'
                        columns={columns} 
                        dataSource={data} 
                        scroll={{x: 1300}}
                        className='table-dark pagination'
                        rowHoverable={false}
                        pagination={{
                            hideOnSinglePage: true,
                            total: 12,
                            // pageSize: pagination?.pageSize,
                            // defaultPageSize: pagination?.pageSize,
                            // current: pagination?.pageNo,
                            // size: "default",
                            // pageSizeOptions: ['10', '20', '50', '100'],
                            // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                            showTotal: (total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>,
                        }}
                    />
                </Col>
            </Row>
    </div>
  )
}

export {TableViewCouponManage}