import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col ,Button, Space, Typography, Radio, Flex, Checkbox } from "antd"
import { MyInput, MySelect } from "../../Forms"

const { Title, Text } = Typography;

const ExportReservationDrawer = ({visible, onClose}) => {
  
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)
    const [value, setValue] = useState(1);
    const onChange = (e) => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
    };  

    const onChangeHandle = (e) => {
        console.log('checked', e.target.value);
    };  
  
    useEffect(() => {
        if (visible) {
            
        } else {
            form.resetFields();
        }
    }, [visible])

    const closeDrawer= ()=>{
     
        form.resetFields()
        onClose()
    }


  return (
    <Drawer
        title={'Export Reservations'}
        onClose={closeDrawer}
        className="drawer"
        open={visible}
        width={600}
        footer={
            <Space className="w-100 right">
                <Button 
                    onClick={closeDrawer}
                    className="btncancel"
                >
                    Cancel
                </Button>
                <Button  
                    block
                    className='btnsave'
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    onClick={()=> form.submit()}
                >
                    Export
                </Button>
            </Space>
        }
    >
        <Form
            form={form}
            layout="vertical"
            initialValues={true}
            // onFinish={onFinish}
            requiredMark={false}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Title level={5} className="mt-0 mb-3 fw-500">EXPORT DETAILS</Title>
                </Col>
                <Col span={24}>
                    <Flex gap={30}>
                        <Text className='fs-13'>Export As</Text>
                        <Radio.Group onChange={onChange} value={value}>
                            <Space className="mb-3">
                                <Radio value={1} className="fs-13">Excel</Radio>
                                <Radio value={2} className="fs-13">CSV</Radio>
                            </Space>
                        </Radio.Group>
                    </Flex>
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                    <MySelect
                        label= 'Filter Reservations By'
                        name="reservation" 
                        size={'large'}
                        placeholder='Salon name'
                        required
                        mode="multiple"
                        message='Please choose reservation by'
                        value={form.getFieldValue("reservation") || ''}
                        options={[
                            {
                                key: 1,
                                name: 'Salon Name'
                            },
                            {
                                key: 2,
                                name: 'City'
                            },
                            {
                                key: 3,
                                name: 'Gender'
                            },
                        ]}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                    <MySelect
                        label= 'Salon Name'
                        name="salonName" 
                        size={'large'}
                        placeholder='e.g HH Salon'
                        required
                        mode="multiple"
                        message='Please choose salon name'
                        value={form.getFieldValue("salonName") || ''}
                        options={[
                            {
                                key: 1,
                                name: 'Salon 01'
                            },
                            {
                                key: 2,
                                name: 'Salon 02'
                            },
                        ]}
                    />
                </Col>
                <Col span={24}>
                    <Title level={5} className="mt-0 mb-3 fw-500">RESERVATION FIELD</Title>
                </Col>
                <Col span={24}>
                    <Space direction="vertical">
                        <Checkbox onChange={onChangeHandle}>Select All Fields</Checkbox>
                        <Checkbox onChange={onChangeHandle}>Reservation Number</Checkbox>
                        <Checkbox onChange={onChangeHandle}>Salon Name</Checkbox>
                        <Checkbox onChange={onChangeHandle}>Client Name</Checkbox>
                        <Checkbox onChange={onChangeHandle}>Contact Number</Checkbox>
                        <Checkbox onChange={onChangeHandle}>Amount</Checkbox>
                        <Checkbox onChange={onChangeHandle}>Payment Method</Checkbox>
                        <Checkbox onChange={onChangeHandle}>Status</Checkbox>
                        <Checkbox onChange={onChangeHandle}>Time Stamp</Checkbox>
                        <Checkbox onChange={onChangeHandle}>Services</Checkbox>
                    </Space>
                </Col>
            </Row>
        </Form>
    </Drawer>
  )
}

export {ExportReservationDrawer}