import React, { useState } from 'react'
import { Card, Col, Row, Typography,Space, Image, theme, Flex, Switch, Divider } from 'antd'
import { ActionButton } from '../../PageComponents';

const { useToken } = theme;
const { Text } = Typography
const GridViewCouponManage = ({setEditItem, setDeleteItem, setVisible, setSwitchText}) => {
    const { token } = useToken();

    const onChange = (checked) => {
        console.log(`switch to ${checked}`);
        setDeleteItem(true)
        setSwitchText(true)
    };
    

    const data = [
        {
            title:'THX2SAVING (30)',
            per:'Percentage - 10%',
            key: 'active'
        },
        {
            title:'TAKE15OFF (10)',
            per:'Percentage - 10%',
            key: 'active'
        },
        {
            title:'SALE25NOW (5)',
            per:'Percentage - 10%',
        },
        {
            title:'SAVE10NOW (7)',
            per:'Fixed - 100%',
            key:'active'
        },
        {
            title:'THX2SAVING (12)',
            per:'Percentage - 20%',
            key:'active'
        },
        {
            title:'BOGO50 (5)',
            per:'Percentage - 10%',
            key:'active'
        },
        {
            title:'TAKE15OFF (20)',
            per:'Percentage - 50%',
        },
        {
            title:'FREESHIP75 (6)',
            per:'Percentage - 10%',
            key:'active'
        },


        {
            title:'CLEARANCE15 (8)',
            per:'Percentage - 20%',
            key:'active'
        },
        {
            title:'REWARDS4U (25)',
            per:'Percentage - 10%',
        },
        {
            title:'BUYNOW20 (40)',
            per:'Fixed - 100%',
            key:'active'
        },
        {
            title:'HURRY (23)',
            per:'Percentage - 10%',
            key:'active'
        },
    ]
  return (
    <div>
        <Row gutter={[16,16]}>
            {
                data?.map((cdata,c)=>
                    <Col xs={24} sm={24} md={12} lg={8} xl={6} key={c}>
                        <Card style={{
                            borderRadius: token.borderCardrad,
                            width:"100%"}}
                            actions={[
                                <Space gap={10}>
                                    <Switch size='small' defaultChecked onChange={onChange} />
                                    <ActionButton
                                        title='Edit item'
                                        shape="circle"
                                        icon={<Image src='/assets/icons/edit.png' width={20} preview={false} />}
                                        onClick={() => {setVisible(true); setEditItem({name: cdata?.title})}}
                                        className='border0 bg-transparent'
                                    />
                                    <ActionButton
                                        title='Delete item'
                                        icon={<Image src='/assets/icons/delete.png' width={22} preview={false} />}
                                        onClick={() => { setDeleteItem({name: cdata?.title}) }}
                                        className='border0 bg-transparent'
                                    />
                                </Space>
                            ]}
                        >
                        <Flex vertical gap={5} align='center'>
                            <Flex align='center' gap={5} className='pt-3'>
                                <img src={
                                    cdata?.key === 'active' ? '/assets/icons/online.png' : '/assets/icons/offline.png'
                                } 
                                width={10} alt="" 
                                />
                                <Text strong>{cdata?.title}</Text>
                            </Flex>
                            <Text className='text-gray fs-13'>{cdata?.per}</Text>
                        </Flex>
                    </Card>  
                </Col>
                )
            }
        </Row>
    </div>
  )
}

export {GridViewCouponManage}