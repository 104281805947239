import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col ,Button, Space, message, Typography, Upload } from "antd"
import { MyDatepicker, MyInput, MySelect, NolabelInputSm } from "../../Forms"

const { Title } = Typography;

const AddEditCouponDrawer = ({visible, onClose, edititem}) => {
  
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)  
  
    useEffect(() => {
        if (visible && edititem) {
            
        } else {
            form.resetFields();
        }
    }, [visible, edititem])

    const closeDrawer= ()=>{
     
        form.resetFields()
        onClose()
    }


  return (
    <Drawer
        title={edititem?'Edit coupon':'Add coupon'}
        onClose={closeDrawer}
        className="drawer"
        open={visible}
        width={600}
        footer={
            <Space className="w-100 right">
                <Button 
                    onClick={closeDrawer}
                    className="btncancel"
                >
                    Cancel
                </Button>
                <Button  
                    block
                    className='btnsave'
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    onClick={()=> form.submit()}
                >
                    {edititem? 'Update' : 'Save'}
                </Button>
            </Space>
        }
    >
        <Form
            form={form}
            layout="vertical"
            initialValues={true}
            // onFinish={onFinish}
            requiredMark={false}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Title level={5} className="mt-0 mb-3">COUPON DETAILS</Title>
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Coupon Code'
                        name='couponCode'
                        placeholder='e.g UPTO15'
                        required
                        size={'large'}
                        message='Please enter coupon code'
                        value={form.getFieldValue("couponCode") || ''}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Number of Use'
                        name='numberUse'
                        placeholder='e.g 20'
                        size={'large'}
                        required
                        message='Please enter number of use'
                        value={form.getFieldValue("numberUse") || ''}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyDatepicker
                        datePicker
                        name="startDate"
                        label="Start Date"
                        size={'large'}
                        required
                        message='Please enter start date'
                        value={form.getFieldValue("startDate") || ''}
                        className='border-black'
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyDatepicker
                        datePicker
                        name="expDate"
                        label="Expiration Date"
                        size={'large'}
                        required
                        message='Please enter expiration date'
                        value={form.getFieldValue("expDate") || ''}
                        className='border-black'
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MySelect
                        label= 'Coupon Type'
                        name="couponType" 
                        size={'large'}
                        required
                        message='Please choose coupon type'
                        value={form.getFieldValue("couponType") || ''}
                        options={[
                            {
                                key: 1,
                                name: 'Fixed'
                            },
                            {
                                key: 2,
                                name: 'Percentage'
                            },
                        ]}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Amount/Percentage'
                        name='amount'
                        type='number'
                        placeholder='e.g 25'
                        size={'large'}
                        required
                        message='Please enter amount'
                        value={form.getFieldValue("amount") || ''}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MySelect
                        label= 'The salons to which the coupon is applied'
                        name="couponApplied" 
                        size={'large'}
                        required
                        message='Please choose applied coupon'
                        value={form.getFieldValue("couponApplied") || ''}
                        options={[
                            {
                                key: 1,
                                name: 'Coupon 1'
                            },
                            {
                                key: 2,
                                name: 'Coupon 2'
                            },
                        ]}
                    />
                </Col>
            </Row>
        </Form>
    </Drawer>
  )
}

export {AddEditCouponDrawer}