import React, { useState } from 'react';
import { Button, Card, Col, Form, Row, Space, Typography } from 'antd';
import { ModuleTopHeading, MyInput } from '../../components';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const { Text } = Typography

const PrivacyPolicyPage = () => {
    const [form] = Form.useForm();
    const [value, setValue] = useState('All Users are requested to provide personal information to Shafrah such as first and last name, email address, phone number/mobile number, physical addresses all referred to collectively as Personal Information . All data is stored in encrypted form. We protect all Personal Information provided to us to help protect Your security and confidentiality.The User’s information will only be disclosed under the duty to comply with any legal obligation or court of law requesting to do so.ing3');

    const [value1, setValue1] = useState('يُطلب من جميع المستخدمين تقديم معلومات شخصية إلى Shafrah مثل الاسم الأول والأخير وعنوان البريد الإلكتروني ورقم الهاتف/رقم الهاتف المحمول والعناوين الفعلية، والتي يشار إليها جميعها مجتمعة بالمعلومات الشخصية. يتم تخزين جميع البيانات في شكل مشفر. نحن نحمي جميع المعلومات الشخصية المقدمة لنا للمساعدة في حماية أمنك وسريتك. لن يتم الكشف عن معلومات المستخدم إلا بموجب واجب الامتثال لأي التزام قانوني أو محكمة تطلب القيام بذلك.');

    const modules = {
        toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image'],
            ['clean'],
        ],
    };

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image',
    ];

    return (
        <div>
            <Card className="shadow-c radius-12 border0">
                <Form
                    form={form}
                    layout="vertical"
                >
                    <Row gutter={16}>
                        <Col span={24}>
                            <div className="mb-3">
                                <ModuleTopHeading name="Privacy Policy" />
                            </div>
                        </Col>
                        <Col lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                            <MyInput
                                name="nameEng"
                                label="Name in English"
                                placeholder="Privacy Policy"
                                value={form.getFieldValue("nameEng") || ''}
                                className="w-100 bg-transparent"
                            />
                        </Col>
                        <Col lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                            <MyInput
                                name="nameArab"
                                label="Name in Arabic"
                                placeholder="سياسة الخصوصية"
                                value={form.getFieldValue("nameArab") || ''}
                                className="w-100 bg-transparent"
                            />
                        </Col>
                        <Col span={24} className='mb-3'>
                            <Text className='pb-1 block'>Body in English</Text>
                            <ReactQuill
                                theme="snow"
                                value={value}
                                onChange={setValue}
                                modules={modules}
                                formats={formats}
                            />
                        </Col>
                        <Col span={24} className='mb-3'>
                            <Text className='pb-1 block'>Body in Arabic</Text>
                            <ReactQuill
                                theme="snow"
                                value={value1}
                                onChange={setValue1}
                                modules={modules}
                                formats={formats}
                            />
                        </Col>
                        <Col span={24}>
                            <Space className="w-100 right">
                                <Button 
                                    className="btncancel"
                                >
                                    Cancel
                                </Button>
                                <Button  
                                    block
                                    className='btnsave'
                                    type="primary"
                                    htmlType="submit"
                                    onClick={()=> form.submit()}
                                >
                                    Save
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    );
};

export { PrivacyPolicyPage };
