import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col ,Button, Space, message, Typography, Upload, Image} from "antd"
import { MyInput, MySelect, NolabelInputSm } from "../../Forms"
// import { LocationMap } from "../LocationMap";

const { Title } = Typography;
const { Dragger } = Upload;

const AddEditEmployeeDrawer = ({visible, onClose, edititem}) => {
  
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)  
  
    useEffect(() => {
        if (visible && edititem) {
            
        } else {
            form.resetFields();
        }
    }, [visible, edititem])

    const closeDrawer= ()=>{
     
        form.resetFields()
        onClose()
    }

    const selectBefore = (
        <NolabelInputSm 
            name='code'
            placeholder='+996'
            type='number'
            value={form.getFieldValue("code") || ''}
            className='p-0'
            style={{width: 60}}
        />
      );

    const props = {
        name: 'file',
        action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
        headers: {
          authorization: 'authorization-text',
        },
        onChange(info) {
          if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
          }
          if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
          } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
          }
        },
      };

  return (
    <Drawer
        title={edititem?'Edit employee':'Add employee'}
        onClose={closeDrawer}
        className="drawer"
        open={visible}
        width={600}
        footer={
            <Space className="w-100 right">
                <Button 
                    onClick={closeDrawer}
                    className="btncancel"
                >
                    Cancel
                </Button>
                <Button  
                    block
                    className='btnsave'
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    onClick={()=> form.submit()}
                >
                    {edititem? 'Update' : 'Save'}
                </Button>
            </Space>
        }
    >
        <Form
            form={form}
            layout="vertical"
            initialValues={true}
            // onFinish={onFinish}
            requiredMark={false}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Title level={5} className="mt-0 mb-3 fw-500">EMPLOYEE DETAILS</Title>
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Employee Name'
                        name='empName'
                        placeholder='e.g Abdullah'
                        required
                        size={'large'}
                        message='Please enter employee name'
                        value={form.getFieldValue("empName") || ''}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MySelect
                        label= 'Role'
                        name="role" 
                        size={'large'}
                        required
                        message='Please choose role'
                        value={form.getFieldValue("role") || ''}
                        options={[
                            {
                                key: 1,
                                name: 'Data entery'
                            },
                            {
                                key: 2,
                                name: 'Admin'
                            },
                        ]}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Email Address'
                        name='email'
                        placeholder='e.g abc@gmail.com'
                        size={'large'}
                        required
                        message='Please enter email address'
                        value={form.getFieldValue("email") || ''}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Password'
                        name='password'
                        type='password'
                        size={'large'}
                        required
                        message='Please enter password'
                        value={form.getFieldValue("password") || ''}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MySelect
                        label= 'Gender'
                        name="gender" 
                        size={'large'}
                        required
                        message='Please choose gender'
                        value={form.getFieldValue("gender") || ''}
                        options={[
                            {
                                key: 1,
                                name: 'Male'
                            },
                            {
                                key: 2,
                                name: 'Female'
                            },
                            {
                                key: 3,
                                name: 'Other'
                            },
                        ]}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        name="phoneNumber"
                        label="Phone Number"
                        type='number'
                        size={'large'}
                        required
                        addonBefore={selectBefore}
                        message='Enter phone number'
                        value={form.getFieldValue("phoneNumber") || ''}
                    />
                </Col>
            </Row>
        </Form>
    </Drawer>
  )
}

export {AddEditEmployeeDrawer}