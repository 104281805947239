import React,{ useState } from 'react'
import { Button, Row, Col, Form, Typography, Table, Image, Space, Avatar, Switch } from 'antd'
import { ActionButton } from '../../PageComponents';


const { Text } = Typography


const ServicesReservationTable = () => {
    const [form] = Form.useForm();

    const columns = [
        {
            title: <Text>#</Text>,
            dataIndex: 'index',
            width:80
        },
        {
            title: <Text>Salon Name</Text>,
            dataIndex: 'salonName',
        },
        {
            title: <Text>Service Name</Text>,
            dataIndex: 'serviceName',
        },
        {
            title: <Text>Employee Name</Text>,
            dataIndex: 'employeeName',
        },
        {
            title: <Text>Amount</Text>,
            dataIndex: 'amount',
        },
        {
            title: <Text>Date</Text>,
            dataIndex: 'date',
        },
        {
            title: <Text>To</Text>,
            dataIndex: 'to',
        },
        {
            title: <Text>From</Text>,
            dataIndex: 'from',
        },
    ];

    const data = [
        {
            key: '1',
            index:'#11',
            salonName: <Text>Timeless Style</Text>,
            serviceName: <Text>Hair Dye</Text>,
            employeeName: <Text>Sajid Hussain</Text>,
            amount: <Text>SAR 25</Text>,
            date: <Text>9/18/16</Text>,
            to: <Text>11:02</Text>,
            from: <Text>01:03</Text>,
        },
        {
            key: '2',
            index:'#12',
            salonName: <Text>Hair Artistry</Text>,
            serviceName: <Text>Sameh Amin</Text>,
            employeeName: <Text>Sajid Hussain</Text>,
            amount: <Text>SAR 25</Text>,
            date: <Text>9/18/16</Text>,
            to: <Text>11:02</Text>,
            from: <Text>01:03</Text>,
        },
      ];

  return (
    <div>
        <Row gutter={[24,24]}>
            <Col span={24}>
                <Table 
                    size='large'
                    columns={columns} 
                    dataSource={data} 
                    scroll={{x: 1000}}
                    className='table-dark pagination'
                    rowHoverable={false}
                    pagination={false}
                />
            </Col>
        </Row>
    </div>
  )
}

export {ServicesReservationTable}