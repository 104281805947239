import React,{ useState } from 'react'
import { Row, Col, Form, Typography, Table, Image, Space } from 'antd'
import { ActionButton, ModuleTopHeading } from '../../PageComponents';
import { AlertModal } from '../../Modals';


const { Text } = Typography


const BookingReportTable = () => {

    const [form] = Form.useForm();
    const [ visible, setVisible ] = useState(false)
    const [ editbook, setEditBook ] = useState(null)
    const [ deleteitem, setDeleteItem ] = useState(false)
    const [ reportonly, setReportOnly ] = useState(true)
  

    const columns = [
        {
            title: <Text className='text-white'>Guest Name</Text>,
            dataIndex: 'guestName',
        },
        {
            title: <Text className='text-white'>Number of Nights</Text>,
            dataIndex: 'numberOfNights',
        },
        {
            title: <Text className='text-white'>Check-in Date</Text>,
            dataIndex: 'checkinDate',
        },
        {
            title: <Text className='text-white'>Check-out Date</Text>,
            dataIndex: 'checkoutDate',
        },
        {
            title: <Text className='text-white'>Added By</Text>,
            dataIndex: 'addedBy',
        },
        {
            title: <Text className='text-white'>Time Stamp</Text>,
            dataIndex: 'timeStamp',
        },
        {
            title: <Text className='text-white'>Action</Text>,
            key: 'action',
            fixed: 'right',
            width: 150,
            render: (_, row) => (
                <Space>
                    <ActionButton
                        title='Edit item'
                        shape="circle"
                        icon={<Image src='/assets/icons/edit.png' width={16} preview={false} />}
                        onClick={() => { setVisible(true); setEditBook(true)}}
                        className='border0 bg-transparent'
                    />
                    <ActionButton
                        title='Delete item'
                        icon={<Image src='/assets/icons/delete.png' width={20} preview={false} />}
                        onClick={() => { setDeleteItem({name:row?.guestName}) }}
                        className='border0 bg-transparent'
                    />
                </Space>
            ),
        },
    ];

    const data = [
        {
            key: '1',
            guestName: <Text className='text-white'>Sajid</Text>,            
            numberOfNights: <Text className='text-white'>3</Text>,
            checkinDate: <Text className='text-white'>5/30/14</Text>,
            checkoutDate: <Text className='text-white'>5/30/14</Text>,
            addedBy: <Text className='text-white'>Admin</Text>,
            timeStamp: <Text className='text-white'>11:02PM 9/18/16</Text>,
        },
        {
            key: '2',
            guestName: <Text className='text-white'>Sajid</Text>,            
            numberOfNights: <Text className='text-white'>3</Text>,
            checkinDate: <Text className='text-white'>5/30/14</Text>,
            checkoutDate: <Text className='text-white'>5/30/14</Text>,
            addedBy: <Text className='text-white'>Admin</Text>,
            timeStamp: <Text className='text-white'>11:02PM 9/18/16</Text>,
        },
        {
            key: '3',
            guestName: <Text className='text-white'>Sajid</Text>,            
            numberOfNights: <Text className='text-white'>3</Text>,
            checkinDate: <Text className='text-white'>5/30/14</Text>,
            checkoutDate: <Text className='text-white'>5/30/14</Text>,
            addedBy: <Text className='text-white'>Admin</Text>,
            timeStamp: <Text className='text-white'>11:02PM 9/18/16</Text>,
        },
        {
            key: '4',
            guestName: <Text className='text-white'>Sajid</Text>,            
            numberOfNights: <Text className='text-white'>3</Text>,
            checkinDate: <Text className='text-white'>5/30/14</Text>,
            checkoutDate: <Text className='text-white'>5/30/14</Text>,
            addedBy: <Text className='text-white'>Admin</Text>,
            timeStamp: <Text className='text-white'>11:02PM 9/18/16</Text>,
        },
        {
            key: '5',
            guestName: <Text className='text-white'>Sajid</Text>,            
            numberOfNights: <Text className='text-white'>3</Text>,
            checkinDate: <Text className='text-white'>5/30/14</Text>,
            checkoutDate: <Text className='text-white'>5/30/14</Text>,
            addedBy: <Text className='text-white'>Admin</Text>,
            timeStamp: <Text className='text-white'>11:02PM 9/18/16</Text>,
        },
      ];

  return (
    <div>
        <Row gutter={[24,24]}>
            <Col span={24}>
                <ModuleTopHeading 
                    name='Booking Details'
                    onClick={()=>{setVisible(true);setReportOnly(true)}}
                />
            </Col>
            <Col span={24}>
                <Table 
                    size='large'
                    columns={columns} 
                    dataSource={data} 
                    scroll={{x: 1000}}
                    className='table-dark pagination'
                    rowHoverable={false}
                    pagination={false}
                />
            </Col>
        </Row>
        {/* <AddEditBookingDrawer
            visible={visible}
            editbook={editbook}
            reportonly={reportonly}
            onClose={()=> {setVisible(false); setEditBook(null) }}
        /> */}
        <AlertModal 
            visible={deleteitem}
            onClose={()=> setDeleteItem(false)}
        />
    </div>
  )
}

export {BookingReportTable}