import React, { useState } from 'react'
import { Card, Col, Row, Typography,Space, Image, theme, Flex, Switch, Divider } from 'antd'
import { ActionButton } from '../../PageComponents';

const { useToken } = theme;
const { Text } = Typography
const GridViewReviews = ({ setDeleteItem, setVisible }) => {
    const { token } = useToken();
    const [expandedStates, setExpandedStates] = useState({});

    const data = [
        {
            profileimg:'bannerimg-1.png',
            ptitle:'Royal Salon',
            preg:'Reservation No: 234',
            rtitle:'M. Abdullah',
            review:'Best services! Highly Recommended.',
            date:'22/03/2021',
            reviewstars:[
                {
                    icons:'star.png',
                },
                {
                    icons:'star.png',
                },
                {
                    icons:'star.png',
                },
                {
                    icons:'star.png',
                },
            ]
        },
        {
            profileimg:'bannerimg-2.png',
            ptitle:'Beauty DD Salon',
            preg:'Reservation No: 234',
            rtitle:'Colleen',
            review:'Fermentum, consectetur nam magnis viverra suspendisse quam condimentum varius.',
            date:'22/03/2021',
            reviewstars:[
                {
                    icons:'star.png',
                },
                {
                    icons:'star.png',
                },
                {
                    icons:'star.png',
                },
                {
                    icons:'star.png',
                },
                {
                    icons:'star.png',
                },
            ]
        },
        {
            profileimg:'bannerimg-3.png',
            ptitle:'Classique Curls',
            preg:'Reservation No: 234',
            rtitle:'Brandie',
            review:'There’s no other program that walks you through exactly what you need to know to start an online store fast, written by someone who has built several 7-figure ecommerce businesses from scratch. ',
            date:'22/03/2021',
            reviewstars:[
                {
                    icons:'star.png',
                },
                {
                    icons:'star.png',
                },
                {
                    icons:'star.png',
                },
                {
                    icons:'star.png',
                },
                {
                    icons:'star.png',
                },
            ]
        },
        {
            profileimg:'bannerimg-4.png',
            ptitle:'The Cleanup',
            preg:'Reservation No: 234',
            rtitle:'Claire',
            review:'Fermentum, consectetur nam magnis viverra suspendisse quam condimentum varius.',
            date:'22/03/2021',
            reviewstars:[
                {
                    icons:'star.png',
                },
                {
                    icons:'star.png',
                },
                {
                    icons:'star.png',
                },
                {
                    icons:'star.png',
                },
                {
                    icons:'star.png',
                },
            ]
        },
    ]

    const handleExpand = (index, expanded) => {
        setExpandedStates(prevState => ({
            ...prevState,
            [index]: expanded
        }));
    };

  return (
    <div>
        <Row gutter={[16,16]}>
            {
                data?.map((cdata,index)=>
                    <Col xs={24} sm={24} md={12} lg={8} xl={8} key={index}>
                        <Card style={{
                            borderRadius: token.borderCardrad,position:'relative'}}
                            className='pad-card w-100 height-100'
                        >
                            <Flex justify='space-between' align='flex-start' wrap> 
                                <Flex align='center' gap={5}>
                                    <img src={"/assets/images/"+cdata?.profileimg} width={45} height={45} style={{borderRadius:50}}  alt="" />
                                    <Flex vertical gap={0}>
                                        <Text strong className='fs-13'>{cdata?.ptitle}</Text>
                                        <Text className='text-gray fs-13'>{cdata?.preg}</Text>
                                    </Flex>
                                </Flex>
                                <Space size={0} key={index}>
                                    {
                                        cdata?.reviewstars?.map((stars,index)=>
                                            <Image key={index} src={'/assets/icons/'+stars?.icons} width={15} preview={false} />
                                        )
                                    }
                                </Space>
                            </Flex>
                            <Divider className='mb-2' style={{borderWidth:3}} />
                            <Flex vertical gap={0}>
                                <Text strong>{cdata?.rtitle}</Text>
                                <Typography.Paragraph
                                    className="text-gray"
                                    ellipsis={{
                                        rows: 2,
                                        expandable: true,
                                        symbol: expandedStates[index] ? 'less' : 'more',
                                        onExpand: (expanded) => handleExpand(index, expanded),
                                    }}>
                                    {cdata?.review}
                                </Typography.Paragraph>
                            </Flex>
                            <Flex justify='space-between' align='center'>
                                <Space size={0} key={index}>
                                    {
                                        cdata?.reviewstars?.map((stars,index)=>
                                            <Image key={index} src={'/assets/icons/'+stars?.icons} width={15} preview={false} />
                                        )
                                    }
                                </Space>
                                <Text className='text-gray'>{cdata?.date}</Text>
                            </Flex>
                            <ActionButton
                                title='Delete item'
                                icon={<Image src='/assets/icons/deletecr.png' width={20} preview={false} />}
                                onClick={() => { setDeleteItem({name: cdata?.ptitle}) }}
                                className='border0 bg-transparent postion-btn'
                            />
                        </Card>  
                    </Col>
                )
            }
        </Row>
    </div>
  )
}

export {GridViewReviews}