import React from 'react'
import { Modal } from 'antd'

const ImagePreviewModal = ({ visible, imageSrc, onClose, title }) => {
  return (
    <Modal
      title={title}
      open={visible}
       className='shadow-c'
      footer={null}
      onCancel={onClose}
      centered
    >
      <img alt="preview" style={{ width: '100%' }} src={imageSrc} />
    </Modal>
  )
}

export {ImagePreviewModal}
