import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col ,Button, Space, message, Typography, Upload, Image} from "antd"
import { MyInput, MySelect, NolabelInputSm } from "../../Forms"
import { LocationMap } from "../LocationMap";

const { Title } = Typography;
const { Dragger } = Upload;

const AddEditBeautySalonDrawer = ({visible, onClose, edititem}) => {
  
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)  
  
    useEffect(() => {
        if (visible && edititem) {
            
        } else {
            form.resetFields();
        }
    }, [visible, edititem])

    const closeDrawer= ()=>{
     
        form.resetFields()
        onClose()
    }

    const selectBefore = (
        <NolabelInputSm 
            name='code'
            placeholder='+996'
            type='number'
            value={form.getFieldValue("code") || ''}
            className='p-0'
            style={{width: 60}}
        />
      );

    const props = {
        name: 'file',
        action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
        headers: {
          authorization: 'authorization-text',
        },
        onChange(info) {
          if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
          }
          if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
          } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
          }
        },
      };

  return (
    <Drawer
        title={edititem?'Edit salon':'Add new salon'}
        onClose={closeDrawer}
        className="drawer"
        open={visible}
        width={600}
        footer={
            <Space className="w-100 right">
                <Button 
                    onClick={closeDrawer}
                    className="btncancel"
                >
                    Cancel
                </Button>
                <Button  
                    block
                    className='btnsave'
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    onClick={()=> form.submit()}
                >
                    {edititem? 'Update' : 'Save'}
                </Button>
            </Space>
        }
    >
        <Form
            form={form}
            layout="vertical"
            initialValues={true}
            // onFinish={onFinish}
            requiredMark={false}
        >
            <Row gutter={16}>
                
                <Col lg={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                    <Form.Item name='contract' >
                        <div className='width-drag'>
                            <Dragger {...props} className="dragcs">
                                <p className="ant-upload-drag-icon mb-0">
                                    <Image src='/assets/icons/plus.png' width={'41.6px'} preview={false} />
                                </p>
                                <Typography.Text className="fs-14 fw-500">Upload Salon Logo</Typography.Text>
                            </Dragger>
                        </div>
                        {/* {
                            editproperty &&
                            <Space direction='vertical' size={10}>
                                {
                                    geteditdata?.medical_report?.map((data,index)=>
                                        <div className='viewDownload space-between-align' key={index}>
                                            <Typography.Text className='gray-text fs-12'>
                                                {
                                                    data?.name
                                                }
                                            </Typography.Text>
                                            <ActionButton
                                                title='Delete'
                                                icon={<DeleteOutlined className='danger-color' />}
                                                onClick={()=>{setDeletePatientDoc(true);setDeletePatMed({id:data?.id, name:data?.name})}}
                                                type='ghost'
                                            />
                                        </div>
                                    )
                                }
                            </Space>
                            
                        } */}
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Title level={5} className="mb-3 mt-0">SALON DETAILS</Title>
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Salon name'
                        name='salonName'
                        placeholder='e.g Kidzo Doe'
                        required
                        size={'large'}
                        message='Please enter salon name'
                        value={form.getFieldValue("salonName") || ''}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MySelect
                        label= 'Gender'
                        name="gender" 
                        size={'large'}
                        required
                        message='Please choose gender'
                        value={form.getFieldValue("gender") || ''}
                        options={[
                            {
                                key: 1,
                                name: 'Male'
                            },
                            {
                                key: 2,
                                name: 'Female'
                            },
                            {
                                key: 3,
                                name: 'Other'
                            },
                        ]}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Email Address'
                        name='email'
                        placeholder='e.g abc@gmail.com'
                        size={'large'}
                        required
                        message='Please enter email address'
                        value={form.getFieldValue("email") || ''}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label="Password"
                        type='password'
                        name='password'
                        size={'large'}
                        required
                        message="Please enter password"
                        value={form.getFieldValue('password') || ''}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label="Slogan (English)"
                        name='sloganEng'
                        size={'large'}
                        required
                        message="Please enter slogan (english)"
                        value={form.getFieldValue('sloganEng') || ''}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        name="phoneNumber"
                        label="Phone Number"
                        type='number'
                        size={'large'}
                        required
                        addonBefore={selectBefore}
                        message='Enter phone number'
                        value={form.getFieldValue("phoneNumber") || ''}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Tax Number'
                        name='taxNumber'
                        placeholder='e.g www.abc.com'
                        required
                        size={'large'}
                        message='Please enter tax number'
                        value={form.getFieldValue("taxNumber") || ''}
                    />
                </Col>
                <Col span={24}>
                    <Title level={5} className="my-3">LOCATION  DETAILS</Title>
                </Col>
                <Col span={24}>
                    <LocationMap />
                </Col>
            </Row>
        </Form>
    </Drawer>
  )
}

export {AddEditBeautySalonDrawer}